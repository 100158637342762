import { useEffect, useState } from 'react';

function useDebounce( value: string, delay: number ) {
	const [ debouncedValue, setDebouncedValue ] = useState( value );
	
	useEffect( () => {
		const handler = setTimeout( () => {
			setDebouncedValue( value );
		}, delay );
		
		return () => {
			clearTimeout( handler );
		};
	}, [ value, delay ] );
	
	return debouncedValue;
}

export { useDebounce };
