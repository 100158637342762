type OccurrenceColumnRow = {
	rowIndex: number;
	defaultValue: string | null;
	valueLabel: string | null;
	rowKey: string;
	label: string;
	options?: SelectOption[];
};

type OccurrenceColumn = {
	columnIndex: number;
	rows: OccurrenceColumnRow[];
};

const columnMap: Record<number, string> = {
	1: 'col-start-1 py-2',
	2: 'col-start-2 px-3 py-2',
	3: 'col-start-3 px-3 py-2',
	4: 'col-start-4 px-3 py-2',
	5: 'col-start-5 px-3 py-2',
	6: 'col-start-6 px-3 py-2',
	7: 'col-start-7 px-3 py-2'
};

const rowIndexMap: Record<number, string> = {
	2: 'row-start-2',
	3: 'row-start-3',
	4: 'row-start-4',
	5: 'row-start-5',
	6: 'row-start-6',
	7: 'row-start-7'
};

const rowLabelMap: Record<number, string> = {
	2: 'Each occurrence',
	3: 'General aggregate',
	4: 'Products and completed operations aggregate',
	5: 'Personal and advertising injury',
	6: 'Damage to premises rented to you',
	7: 'Medical expenses'
};

const rowKeyMap: Record<number, string> = {
	2: 'each',
	3: 'general',
	4: 'products',
	5: 'personal',
	6: 'damage',
	7: 'medical'
};

const medicalValues: SelectOption[] = [
	{ label: '$0', value: '0' },
	{ label: '$5,000', value: '5000' },
	{ label: '$10,000', value: '10000' },
	{ label: '$25,000', value: '25000' },
	{ label: '$50,000', value: '50000' }
];

const columns: OccurrenceColumn[] = [
	{
		columnIndex: 2,
		rows: [
			{
				rowIndex: 2,
				defaultValue: '300000',
				valueLabel: '$300,000',
				rowKey: rowKeyMap[ 2 ],
				label: rowLabelMap[ 2 ]
			},
			{
				rowIndex: 3,
				defaultValue: '600000',
				valueLabel: '$600,000',
				rowKey: rowKeyMap[ 3 ],
				label: rowLabelMap[ 3 ]
			},
			{
				rowIndex: 4,
				defaultValue: '600000',
				valueLabel: '$600,000',
				rowKey: rowKeyMap[ 4 ],
				label: rowLabelMap[ 4 ]
			},
			{
				rowIndex: 5,
				defaultValue: '300000',
				valueLabel: '$300,000',
				rowKey: rowKeyMap[ 5 ],
				label: rowLabelMap[ 5 ]
			},
			{
				rowIndex: 6,
				defaultValue: '100000',
				valueLabel: '$100,000',
				rowKey: rowKeyMap[ 6 ],
				label: rowLabelMap[ 6 ]
			},
			{
				rowIndex: 7,
				defaultValue: '5000',
				valueLabel: null,
				rowKey: rowKeyMap[ 7 ],
				label: rowLabelMap[ 7 ],
				options: medicalValues
			}
		]
	},
	{
		columnIndex: 3,
		rows: [
			{
				rowIndex: 2,
				defaultValue: '500000',
				valueLabel: '$500,000',
				rowKey: rowKeyMap[ 2 ],
				label: rowLabelMap[ 2 ]
			},
			{
				rowIndex: 3,
				defaultValue: '1000000',
				valueLabel: '$1,000,000',
				rowKey: rowKeyMap[ 3 ],
				label: rowLabelMap[ 3 ]
			},
			{
				rowIndex: 4,
				defaultValue: '1000000',
				valueLabel: '$1,000,000',
				rowKey: rowKeyMap[ 4 ],
				label: rowLabelMap[ 4 ]
			},
			{
				rowIndex: 5,
				defaultValue: '300000',
				valueLabel: '$300,000',
				rowKey: rowKeyMap[ 5 ],
				label: rowLabelMap[ 5 ]
			},
			{
				rowIndex: 6,
				defaultValue: '100000',
				valueLabel: '$100,000',
				rowKey: rowKeyMap[ 6 ],
				label: rowLabelMap[ 6 ]
			},
			{
				rowIndex: 7,
				defaultValue: '5000',
				valueLabel: null,
				rowKey: rowKeyMap[ 7 ],
				label: rowLabelMap[ 7 ],
				options: medicalValues
			}
		]
	},
	{
		columnIndex: 4,
		rows: [
			{
				rowIndex: 2,
				defaultValue: '1000000',
				valueLabel: '$1,000,000',
				rowKey: rowKeyMap[ 2 ],
				label: rowLabelMap[ 2 ]
			},
			{
				rowIndex: 3,
				defaultValue: '2000000',
				valueLabel: null,
				rowKey: rowKeyMap[ 3 ],
				label: rowLabelMap[ 3 ],
				options: [
					{ label: '$2,000,000', value: '2000000' },
					{ label: '$3,000,000', value: '3000000' }
				]
			},
			{
				rowIndex: 4,
				defaultValue: '2000000',
				valueLabel: null,
				rowKey: rowKeyMap[ 4 ],
				label: rowLabelMap[ 4 ],
				options: [
					{ label: '$2,000,000', value: '2000000' },
					{ label: '$3,000,000', value: '3000000' }
				]
			},
			{
				rowIndex: 5,
				defaultValue: '1000000',
				valueLabel: '$1,000,000',
				rowKey: rowKeyMap[ 5 ],
				label: rowLabelMap[ 5 ]
			},
			{
				rowIndex: 6,
				defaultValue: '100000',
				valueLabel: '$100,000',
				rowKey: rowKeyMap[ 6 ],
				label: rowLabelMap[ 6 ]
			},
			{
				rowIndex: 7,
				defaultValue: '5000',
				valueLabel: null,
				rowKey: rowKeyMap[ 7 ],
				label: rowLabelMap[ 7 ],
				options: medicalValues
			}
		]
	},
	{
		columnIndex: 5,
		rows: [
			{
				rowIndex: 2,
				defaultValue: '2000000',
				valueLabel: '$2,000,000',
				rowKey: rowKeyMap[ 2 ],
				label: rowLabelMap[ 2 ]
			},
			{
				rowIndex: 3,
				defaultValue: '2000000',
				valueLabel: null,
				rowKey: rowKeyMap[ 3 ],
				label: rowLabelMap[ 3 ],
				options: [
					{ label: '$2,000,000', value: '2000000' },
					{ label: '$3,000,000', value: '3000000' },
					{ label: '$4,000,000', value: '4000000' }
				]
			},
			{
				rowIndex: 4,
				defaultValue: '2000000',
				valueLabel: null,
				rowKey: rowKeyMap[ 4 ],
				label: rowLabelMap[ 4 ],
				options: [
					{ label: '$2,000,000', value: '2000000' },
					{ label: '$3,000,000', value: '3000000' },
					{ label: '$4,000,000', value: '4000000' }
				]
			},
			{
				rowIndex: 5,
				defaultValue: '2000000',
				valueLabel: '$2,000,000',
				rowKey: rowKeyMap[ 5 ],
				label: rowLabelMap[ 5 ]
			},
			{
				rowIndex: 6,
				defaultValue: '100000',
				valueLabel: '$100,000',
				rowKey: rowKeyMap[ 6 ],
				label: rowLabelMap[ 6 ]
			},
			{
				rowIndex: 7,
				defaultValue: '5000',
				valueLabel: null,
				rowKey: rowKeyMap[ 7 ],
				label: rowLabelMap[ 7 ],
				options: medicalValues
			}
		]
	},
	{
		columnIndex: 6,
		rows: [
			{
				rowIndex: 2,
				defaultValue: null,
				valueLabel: null,
				rowKey: rowKeyMap[ 2 ],
				label: rowLabelMap[ 2 ]
			},
			{
				rowIndex: 3,
				defaultValue: null,
				valueLabel: null,
				rowKey: rowKeyMap[ 3 ],
				label: rowLabelMap[ 3 ]
			},
			{
				rowIndex: 4,
				defaultValue: null,
				valueLabel: null,
				rowKey: rowKeyMap[ 4 ],
				label: rowLabelMap[ 4 ]
			},
			{
				rowIndex: 5,
				defaultValue: null,
				valueLabel: null,
				rowKey: rowKeyMap[ 5 ],
				label: rowLabelMap[ 5 ]
			},
			{
				rowIndex: 6,
				defaultValue: null,
				valueLabel: null,
				rowKey: rowKeyMap[ 6 ],
				label: rowLabelMap[ 6 ]
			},
			{
				rowIndex: 7,
				defaultValue: '5000',
				valueLabel: null,
				rowKey: rowKeyMap[ 7 ],
				label: rowLabelMap[ 7 ],
				options: medicalValues
			}
		]
	}
];

const defaultState: any = columns.map( ( columnObj: any ) => {
	let returnObj: any = {};

	columnObj.rows.forEach( ( row: any ) => {
		returnObj[ row.rowKey ] = row.defaultValue;
	});

	return returnObj;
});

export { columnMap, columns, defaultState, rowIndexMap };
