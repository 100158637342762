import { useCallback, useState } from 'react';
import Select from 'react-select';
import { Controller, useFormContext } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';

import { useDebounce } from '@/hooks/useDebounce';
import { useTheme } from '@/hooks/useTheme';

type PredictiveSelectProps = {
	defaultValue: any;
	isMulti?: boolean;
	name: string;
	noOptionsMessage?: string;
	placeholder: string;
	queryFunction: any;
	queryKey: any;
};

export default function PredictiveSelect({
	defaultValue,
	isMulti = true,
	name,
	noOptionsMessage,
	placeholder,
	queryFunction = () => {},
	queryKey
}: PredictiveSelectProps ) {
	const [ searchValue, setSearchValue ] = useState( '' );
	const debouncedFilter = useDebounce( searchValue, 500 );

	const { data = [] } = useQuery(
		[ queryKey, debouncedFilter ],
		() => queryFunction( debouncedFilter ),
		{ enabled: Boolean( debouncedFilter ) }
	);

	const { control } = useFormContext();

	const { getThemeColor } = useTheme();

	const customSelectStyles = {
		control: ( provided: any ) => ({
			...provided,
			borderRadius: '1px',
			borderColor: getThemeColor( 'rural-gray.500' )
		}),
		indicatorSeparator: ( provided: any ) => ({
			...provided,
			display: 'none'
		}),
		placeholder: ( provided: any ) => ({
			...provided,
			color: getThemeColor( 'rural-gray.400' )
		})
	};

	const handleInputChange = useCallback( ( inputText: string, event: any ) => {
		if ( event.action === 'input-blur' || event.action === 'menu-close' ) {
			return;
		}
		
		setSearchValue( inputText );
	}, [ setSearchValue ] );

	return (
		<Controller
			name={ name }
			control={ control }
			defaultValue={ defaultValue }
			render={ ({ field }) => {
				return (
					<Select
						{ ...field }
						instanceId={ name }
						isMulti={ isMulti }
						noOptionsMessage={ () => noOptionsMessage }
						options={ data }
						placeholder={ placeholder }
						styles={ customSelectStyles }
						onInputChange={ handleInputChange }
					/>
				);
			}}
		/>
	);
}
