import Button from '@/components/Button';
import Header from '@/components/Header';

type ConfirmCppDeleteProps = {
	displayId: string;
	onCancel: any;
	onConfirm: any;
};

export default function ConfirmCppDelete({
	displayId,
	onCancel = () => {},
	onConfirm = () => {}
}: ConfirmCppDeleteProps ) {
	return (
		<div>
			<Header element="h2" variant="h3">Delete CPP { displayId }?</Header>

			<p className="text-md">Are you sure you want to delete this CPP? This cannot be undone.</p>

			<div className="flex gap-x-4 mt-8">
				<Button type="button" variant="primary" size="large" onClick={ onConfirm }>Yes, delete</Button>
				<Button type="button" variant="border" size="large" onClick={ onCancel }>Cancel</Button>
			</div>
		</div>
	);
}
