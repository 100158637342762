import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import Button from '@/components/Button';
import Header from '@/components/Header';
import AgentSelect from '@/components/AgentSelect';

import { saveCpp } from '@/services/webservices/cpp';

type AgentSearchProps = {
	onCancel?: () => void;
	onSuccess?: () => void;
	cppId: string;
	agentOptions: SelectOption[];
};

export default function AgentSearch({
	onCancel = () => {},
	onSuccess = () => {},
	cppId
}: AgentSearchProps ) {
	const { handleSubmit, reset } = useFormContext();

	const onClear = useCallback( () => {
		reset();
		onCancel();
	}, [ onCancel, reset ] );

	const onSubmit = useCallback( async ( data: any ) => {
		const agentData = { agentId: data?.associatedAgents?.value };

		let createRequest;

		if ( agentData && agentData?.agentId ) {
			try {
				createRequest = await saveCpp( cppId as string, {}, agentData.agentId );

				onSuccess();
			} catch ( e ) {
				throw new Error( 'Error saving the form' );
			}
	
			onClear();
		}
	}, [ cppId, onClear, onSuccess ] );

	return (
		<div>
			<Header element="h2" variant="h3">Select Agent to associate with this CPP</Header>

			<div className="mt-4">
				<form onSubmit={ handleSubmit( onSubmit ) }>
					<AgentSelect />

					<div className="flex gap-x-4 mt-8">
						<Button type="submit" variant="primary" size="large">Save</Button>
						<Button type="button" variant="border" size="large" onClick={() => onClear()}>Cancel</Button>
					</div>
				</form>
			</div>
		</div>
	);
}
