import type { FieldValues } from 'react-hook-form';

const savedFormId = 'storedForm';

export function clearSavedForm() {
	localStorage.removeItem( savedFormId );
}

export function getSavedForm() {
	const savedValues = localStorage.getItem( savedFormId ) || null;

	return savedValues ? JSON.parse( savedValues ) : {};
}

export function updateSavedForm( formData: FieldValues ) {
	localStorage.setItem( savedFormId, JSON.stringify( formData ) );
}
