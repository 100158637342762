export const changeAgent = [
	// Not allowed user roles for changing agent
];

export const changeUnderwriter = [
	// Not allowed user roles for changing underwriter
	'AGENT',
	'AGENTAST',
	'AGMGR',
	'AGAUTO',
	'SCREENER',
	'BACOORD'
];

export const createCpp = [
	// Not allowed user roles for changing underwriter
	'FIELDUW',
	'HOUW',
	'SCREENER'
];

export const underwriters = [
	'FIELDUW',
	'HOUW'
];

export function isAgentChangeAllow( userRole: string ) {
	return !changeAgent.find( role => userRole === role );
}

export function isUnderwriterChangeAllow( userRole: string ) {
	return !changeUnderwriter.find( role => userRole === role );
}

export function isCreateCppAllowed( userRole: string ) {
	return !createCpp.find( role => userRole === role );
}

export function isUnderwriter( userRole: string ) {
	return underwriters.some( role => userRole === role );
}
