import type { Dispatch, SetStateAction, ReactNode } from 'react';

import { createContext, useContext, useState } from 'react';

type IsExportingProviderProps = {
	isIgnoreConditional?: boolean;
	isPdfExport: boolean;
	overrideEffectiveDate?: Date;
	children: ReactNode;
};

type IsExportingContextProps = {
	isBlankExport: boolean;
	isExporting: boolean;
	exportEffectiveDate?: Date;
	setIsBlankExport: Dispatch<SetStateAction<boolean>>;
	setIsExporting: Dispatch<SetStateAction<boolean>>;
	setExportEffectiveDate: Dispatch<SetStateAction<Date | undefined>>;
};

const IsExportingContext = createContext<IsExportingContextProps | undefined>( undefined );

function IsExportingProvider({
	isIgnoreConditional = false,
	isPdfExport,
	overrideEffectiveDate,
	children
}: IsExportingProviderProps ) {
	const [ isExporting, setIsExporting ] = useState( isPdfExport );
	const [ isBlankExport, setIsBlankExport ] = useState( isIgnoreConditional );
	const [ exportEffectiveDate, setExportEffectiveDate ] = useState<undefined | Date>( overrideEffectiveDate );

	return (
		<IsExportingContext.Provider value={{
			isBlankExport,
			isExporting,
			exportEffectiveDate,
			setIsBlankExport,
			setIsExporting,
			setExportEffectiveDate
		}}>
			{ children }
		</IsExportingContext.Provider>
	);
}

function useIsExporting() {
	const context = useContext( IsExportingContext );

	if ( context === undefined ) {
		throw new Error( 'useIsExporting must be used within a IsExportingProvider' );
	}

	return context;
}

export { IsExportingProvider, useIsExporting };
