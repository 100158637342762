import { createColumnHelper } from '@tanstack/react-table';

import Icon from '@/components/Icon';

export type ChangedItems = {
	formId: string;
	newValue: boolean;
	originalValue: boolean;
	formTitle: string;
	questionTitle: string;
};

const columnHelper = createColumnHelper<ChangedItems>();

function ItemState({
	value
}: any ) {
	return (
		<div className="flex justify-center">
			{ value ? <Icon glyph="eye" size="small" /> : <Icon glyph="eyeSlash" size="small" /> }
		</div>
	);
}

const changedItemsColumns = [
	columnHelper.accessor( 'formTitle', {
		id: 'formTitle',
		header: 'Form',
		cell: info => info.getValue()
	}),
	columnHelper.accessor( 'questionTitle', {
		id: 'questionTitle',
		header: 'Question Label',
		cell: info => info.getValue()
	}),
	// columnHelper.accessor( 'originalValue', {
	// 	id: 'originalValue',
	// 	header: 'Current',
	// 	cell: info => <ItemState value={ info.getValue() } />
	// }),
	columnHelper.accessor( 'newValue', {
		id: 'newValue',
		header: () => <div className="w-full text-center">Change</div>,
		cell: info => <ItemState value={ info.getValue() } />
	})
];

export { changedItemsColumns };
