import Link from 'next/link';
import { createColumnHelper } from '@tanstack/react-table';

export type Location = {
	name: string;
	streetName: string;
	lastModified: string;
	modifiedBy: string;
};

const columnHelper = createColumnHelper<Location>();

const locationColumns = [
	columnHelper.accessor( 'name', {
		id: 'name',
		cell: info => (
			<>
				<Link href="#" className="font-bold text-rural-red-900">
					{ info.getValue() }
				</Link>
			</>
		),
		header: 'Name'
	}),
	columnHelper.accessor( 'streetName', {
		cell: info => info.getValue(),
		header: 'Street name/number or legal description'
	}),
	columnHelper.accessor( 'lastModified', {
		cell: info => info.getValue(),
		header: 'Last modified'
	}),
	columnHelper.accessor( 'modifiedBy', {
		cell: info => info.getValue(),
		header: 'Modified by'
	}),
	columnHelper.display({
		id: 'actions',
		cell: props => <button type="button" className="text-rural-red-900">Edit</button>
	})
];

export { locationColumns };
