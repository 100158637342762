import type { IconDefinition } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faSearch } from '@fortawesome/free-solid-svg-icons';

type Glyph = 'search' | 'eye' | 'eyeSlash';
type IconSize = 'small' | 'medium' | 'large';

type IconProps = {
	color?: string;
	glyph: Glyph;
	size?: IconSize;
};

const SizeMap: Record<IconSize, string> = {
	small: 'max-w-[24px] icon--small',
	medium: 'max-w-[32px] icon--medium',
	large: 'max-w-[44px] icon--large'
};

const IconMap: Record<Glyph, IconDefinition> = {
	search: faSearch,
	eye: faEye,
	eyeSlash: faEyeSlash
};

export default function Icon({
	color = '#555555',
	glyph,
	size = 'medium'
}: IconProps ) {
	const IconDefinition = IconMap[ glyph ];
	const sizeClass = SizeMap[ size ];

	return (
		<div className={ sizeClass }>
			<FontAwesomeIcon icon={ IconDefinition } color={ color } />
		</div>
	);
}
