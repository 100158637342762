import { useMemo, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import parse from 'html-react-parser';

import { useConditionalQuestions } from '@/hooks/useConditionalQuestions';
import { useIsExporting } from '@/hooks/useIsExporting';

export type CheckboxProps = {
	additionalQuestions?: any;
	checkedValues: string[];
	label: string;
	defaultChecked?: boolean;
	readOnly?: boolean;
	required: boolean;
	systemId: string;
	value: string;
};

export default function Checkbox( props: CheckboxProps ) {
	const { isBlankExport } = useIsExporting();

	const {
		checkedValues,
		value,
		label,
		systemId,
		required,
		readOnly,
		defaultChecked = false
	} = props;

	const optionProps = useRef( props );

	const { register, unregister } = useFormContext();

	const isSelected = useMemo( () => {
		if ( isBlankExport ) {
			return false;
		}

		if ( defaultChecked ) {
			return true;
		}

		return Array.isArray( checkedValues ) ? checkedValues.includes( optionProps.current.value ) : checkedValues;
	}, [ checkedValues, defaultChecked, isBlankExport ] );

	const { ConditionalQuestions } = useConditionalQuestions( useMemo( () => ({
		options: [ optionProps.current ],
		selectedValue: isSelected ? [ optionProps.current.value ] : [],
		unregister,
	}), [ isSelected, unregister ] ) );

	return (
		<div className="mb-2">
			<label className="inline-flex items-center gap-2 text-sm">
				<input
					id={ `${ systemId }.${ value }` }
					value={ value }
					type="checkbox"
					className="inline-block border-rural-gray-500 border border-r-2 w-[20px] h-[20px]"
					disabled={ readOnly }
					{ ...register( systemId, {
						required: false,
						validate: ( v: any ) => {
							if ( !required ) {
								return true;
							}

							return v?.length > 0 || 'Please select at least one value';
						}
					})}
				/>

				<span className={ !isBlankExport && readOnly ? `opacity-60` : '' }>{ parse( label ) }</span>
			</label>

			{ ConditionalQuestions }
		</div>
	);
}
