import dayjs from 'dayjs';

import Container from '@/components/Container';

export default function SiteFooter() {
	const currentYear = dayjs().year();

	return (
		<Container className="bg-rural-gray-800 text-white text-sm mt-auto">
			Copyright &copy; { currentYear } Rural Mutual Insurance
		</Container>
	);
}
