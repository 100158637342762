import type { ReactNode } from 'react';

import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { useCpp } from '@/hooks/useCpp';
import { activateOrInactivateForm, getForm } from '@/services/webservices/forms';

type CppFormProviderProps = {
	children: ReactNode;
	formId: string;
};

type CppFormContextExports = {
	formData: any;
	formError: any;
	formId: string;
	formGuid: string;
	formSections: any[];
	formValues: any;
	refetchFormData: Function;
	isFormDraft: boolean;
	isFormInactive: boolean | undefined;
	isFormLoading: boolean;
	toggleFormActivationStatus: Function;
	sectionHiders: Map<string, Function>;
};

const CppFormContext = createContext<CppFormContextExports | undefined>(undefined);

function CppFormProvider({
	children,
	formId
}: CppFormProviderProps) {
	const loadedId = useRef<string | undefined>();

	const { cppData, cppId, refetchCppData } = useCpp();

	const formsCollection = useMemo( () => {
		let mergedApplicationForms: any[];
		
		if ( !cppData?.applications ) {
			return undefined;
		}

		mergedApplicationForms = [ ...cppData?.applications ];
		
		if ( cppData?.questionnaires && cppData?.questionnaires.length > 0 ) {
			mergedApplicationForms = [ ...cppData?.applications, ...cppData?.questionnaires ];
		}

		return mergedApplicationForms;
	}, [ cppData?.applications, cppData?.questionnaires, formId ] );

	const formGuid = useMemo(() => {
		if ( !formsCollection ) {
			return undefined;
		}

		const thisForm = formsCollection?.find( ( application: any ) => application.id == Number( formId ) );

		if ( !thisForm ) {
			return undefined;
		}

		return thisForm.applicationTypeGuid;
	}, [ formsCollection, formId ] );

	const [ formData, setFormData ] = useState<any>( {} );
	const [ formError, setFormError ] = useState<string | undefined>();
	const [ isFormLoading, setIsFormLoading ] = useState( false );

	const fetchFormData = useCallback( async () => {
		if ( !formGuid ) {
			return;
		}

		setIsFormLoading( true );

		try {
			const formData = await getForm( formGuid );

			if ( formData.id ) {
				setFormData( formData );
			} else {
				setFormData( {} );
			}
		} catch ( e: any ) {
			setFormError( e.toString() );
		} finally {
			setIsFormLoading( false );
		}
	}, [ formGuid, setFormData, setFormError, setIsFormLoading ] );

	useEffect( () => {
		if ( !formId || !loadedId.current ) {
			return;
		}

		refetchCppData();
	}, [ formId ] );

	useEffect( () => {
		if ( !formGuid || loadedId.current === formGuid ) {
			return;
		}

		loadedId.current = formGuid;

		fetchFormData();
	}, [ formGuid ] );

	const formValues = useMemo( () => {
		const matchedApplication = formsCollection?.find( ( application: any ) => application.id === Number( formId ) );

		return matchedApplication ? matchedApplication.formData : {};
	}, [ formsCollection, formId ] );

	const isFormInactive = useMemo( () => {
		const matchedApplication = formsCollection?.find( ( application: any ) => application.id == formId );

		return matchedApplication?.inactive || false;
	}, [ formsCollection, formGuid ] );

	const isFormDraft = useMemo( () => {
		const matchedApplication = formsCollection?.find( ( application: any ) => application.id == formId );

		return matchedApplication?.isDraft || false;
	}, [ formsCollection, formGuid ] );

	const toggleFormActivationStatus = useCallback( async () => {
		const activationRequest = await activateOrInactivateForm( cppId, formId, !isFormInactive );

		if ( activationRequest.status === 200 ) {
			refetchCppData();
		}
	}, [ cppId, formId, isFormInactive, refetchCppData ] );

	const formSections = useMemo( () => {
		if ( !formData?.questions || formData.questions.length === 0 ) {
			return [];
		}

		return formData.questions.filter( ( question: any ) => question.fieldType === 'Section' );
	}, [ formData?.questions ] );

	const sectionHiders = new Map();

	const value = useMemo( () => {
		return {
			formData: formData || {},
			formError,
			formId,
			formGuid,
			refetchFormData: fetchFormData,
			formValues,
			isFormDraft,
			isFormLoading,
			isFormInactive,
			toggleFormActivationStatus,
			formSections,
			sectionHiders
		};
	}, [ formData, formError, formId, formGuid, fetchFormData, formValues, isFormLoading, isFormInactive, toggleFormActivationStatus, formSections, sectionHiders ] );

	return (
		<CppFormContext.Provider value={ value }>
			{children}
		</CppFormContext.Provider>
	);
}

function useCppForm() {
	const context = useContext(CppFormContext);

	if (context === undefined) {
		throw new Error('useCppForm must be used within a CppFormProvider');
	}

	return context;
}

export { CppFormProvider, useCppForm };
