import type { ReactNode } from 'react';
import type { FieldValues } from 'react-hook-form';

import { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import Loading from 'react-loading';

import Button from '@/components/Button';

import { IsExportingProvider } from '@/hooks/useIsExporting';
import { useNavigationPrompt } from '@/hooks/useNavigationPrompt';
import { clearSavedForm, getSavedForm, updateSavedForm } from '@/services/localStorage';

type FormProps = {
	children: ReactNode;
	className?: string;
	defaultValues?: any;
	onSubmit: any;
	showNavigationPrompt?: boolean;
	storeData?: boolean;
	formId?: string;
	methods: any;
	isLoading?: boolean;
	hideSubmit?: boolean;
	cancelLink: string;
	onSaveDraft?: any;
};

export default function Form({
	children,
	className,
	defaultValues,
	onSubmit,
	showNavigationPrompt = true,
	storeData = false,
	formId,
	methods,
	isLoading = false,
	hideSubmit = false,
	cancelLink,
	onSaveDraft
} : FormProps ) {
	const { handleSubmit, formState: { dirtyFields, isSubmitSuccessful }, reset, getValues } = methods;

	// checking the value of dirtFields because "isDirty" is returning inconsistent data for some reason.
	// could be related to re-renders of the multi item table component.
	useNavigationPrompt( showNavigationPrompt, Object.keys( dirtyFields ).length > 0, isSubmitSuccessful, 'You have unsaved changes. Are you sure you want to navigate away?' );

	useEffect( () => {
		reset( defaultValues || {} );
	}, [ defaultValues, formId, reset ] );

	useEffect( () => {
		if ( !storeData ) {
			return;
		}

		const storedForm = getSavedForm();

		if ( storedForm.id === formId ) {
			reset( storedForm );
		} else {
			clearSavedForm();
		}
	}, [ formId, reset, storeData ] );

	// Watch all fields for changes and update the unsaved form.
	const checkData = () => {
		if ( storeData ) {
			let theForm = getValues();
			theForm.id = formId;
			
			updateSavedForm( theForm );
		}
	};

	const clearStoredForm = () => {
		setTimeout( function() { // form id still matches when cancelling so wrap in timeout to force this to run after checkData() in Form component
			clearSavedForm();
		}, 0);
	}

	return (
		<IsExportingProvider isPdfExport={ false }>
			<FormProvider { ...methods }>
				<form className={ className } onSubmit={ handleSubmit( ( data: FieldValues ) => onSubmit( data ) ) } onBlur={ checkData } autoComplete="new-password">
					{ children }

					<div className="sticky bg-white/75 bottom-0 flex items-center gap-x-5 pt-2 pb-5 mb-5">
						{ isLoading ? (
							<>
								<span>Saving...</span>

								<Loading type="bubbles" width={ 32 } height={ 32 } color="#777" />
							</>
						): (
							<>
								{ ( !hideSubmit ) && (
									<Button type="submit" variant="primary" size="large">Save</Button>
								)}

								{ onSaveDraft && (
									<Button
										type="button"
										onClick={ onSaveDraft }
										variant="outline"
										size="large"
									>
										Save as Draft
									</Button>
								)}

								<Button
									href={ cancelLink }
									onClick={ clearStoredForm }
									variant="link"
								>
									Cancel
								</Button>
							</>
						)}
					</div>
				</form>
			</FormProvider>
		</IsExportingProvider>
	);
}
