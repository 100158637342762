import { useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';

import { Date } from '@/components/FormElements';
import Header from '@/components/Header';
import { Key, Value } from '@/components/KeyValue';

import { useTheme } from '@/hooks/useTheme';

type SignatureFieldProps = {
	label: string;
};

function SignatureField({
	label
}: SignatureFieldProps ) {
	const signatureElement = useRef<undefined | any>();
	const [ hasSignature, setHasSignature ] = useState( false );
	const { getThemeColor } = useTheme();

	const onEnd = () => {
		if ( !signatureElement.current ) {
			return;
		}

		if ( signatureElement.current.isEmpty() ) {
			setHasSignature( false );
		} else {
			setHasSignature( true );
		}
	};

	const onClear = () => {
		signatureElement.current.clear();

		setHasSignature( false );
	};

	return (
		<div>
			<div className="text-sm mb-1">{ label }</div>
			<div className="flex gap-x-4">
				<div className="signature-icon border border-rural-gray-500 max-w-[500px] py-2">
					<SignatureCanvas
						ref={ ref => signatureElement.current = ref }
						penColor={ getThemeColor( 'rural-gray.700' ) }
						canvasProps={{ width: 430, height: 60 }}
						onEnd={ onEnd }
					/>
				</div>

				{ hasSignature && (
					<button type="button" onClick={ onClear }>Clear</button>
				)}
			</div>
		</div>
	);
}

export default function Signature() {
	return (
		<div>
			<Header element="h2">Signature of applicant</Header>

			<div className="my-6 p-4 border border-rural-gray-500 bg-rural-gray-200">
				<Header element="h3" variant="h4"><span className="uppercase">Coverage Notice:</span></Header>

				<p className="text-xs my-0">The effective date of coverage is not valid until this application for insurance is signed by the applicant and a Rural Mutual Insurance agent.</p>
			</div>

			<div>
				<Header element="h3">Applicant</Header>

				<p className="mb-4 text-xs max-w-prose">I have read the foregoing statements and declare the facts stated on this application to be true and that the company may act in reliance thereon in issuing this coverage endorsement or any renewals thereof.</p>

				<div className="mb-4">
					<Date
						label="Application signed on"
						systemId="applicationSignedOn"
					/>
				</div>

				<div className="mb-4">
					<Key>Applicant name</Key>
					<Value>Caroline Sober-James</Value>
				</div>

				<div className="mb-4">
					<SignatureField label="Applicant signature" />
				</div>
			</div>

			<div>
				<Header element="h3">Agent</Header>

				<div className="mb-4">
					<Key>Agent name</Key>
					<Value>Aaron Reznichek</Value>
				</div>

				<div className="mb-4">
					<Key>Agent number</Key>
					<Value>4406</Value>
				</div>

				<div className="mb-4">
					<SignatureField label="Agent signature" />
				</div>

				<div className="mb-4">
					<Date
						label="Date"
						systemId="agentSignatureDate"
					/>
				</div>
			</div>

			<div>
				<Header element="h3">Proxy Designation</Header>

				<p className="mb-4 text-xs">The undersigned hereby appoints the Wisconsin Farm Bureau Federation Cooperative, by any representative designated by its Board of Directors, attorney in fact for me to vote and act as my proxy at any meeting of policyholders of the Rural Mutual Insuance Company hereafter held, not attended by me in person, granting said attorney full authority to act for me in the transaction of any business coming before such meeting. This Proxy shall be effective upon Applicant becoming a policyholder of Rural Mutual Insurance Company and shall remain in effect indefinitely as long as Applicant remains a policyholder. This Proxy is IRREVOCABLE.</p>

				<div className="mb-4">
					<Date
						label="Date"
						systemId="proxySignatureDate"
					/>
				</div>

				<div className="mb-4">
					<SignatureField
						label="Proxy signature"
					/>
				</div>
			</div>
		</div>
	);
}
