import { useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { Error } from '@/components/Form';
import { Date, Text } from '@/components/FormElements';

type SupportingBusinessProps = {
	systemId: string;
	label: string;
};

const initialValues = [
	{ key: 'businessAuto', label: 'Business Auto' },
	{ key: 'workersComp', label: `Worker's Compensation` },
	{ key: 'businessowners', label: 'Businessowners' }
];

export default function SupportingBusiness({
	systemId,
	label
}: SupportingBusinessProps ) {
	const { control, formState: { errors } } = useFormContext();

	const [ addedFields, setAddedFields ] = useState( 0 );

	const { append, fields, remove } = useFieldArray({
		control,
		name: `${ systemId }`
	});

	return (
		<div>
			<p className="text-sm mb-3">{ label || 'Enter policy number and term.' }</p>

			<table className="keep-it-together">
				<thead>
					<tr className="[&>th]:border-b [&>th]:border-rural-gray-500">
						<th className="text-xs font-bold text-left py-2">Policy / Line of Business</th>
						<th className="text-xs font-bold text-left py-2">Policy Number</th>
						<th className="text-xs font-bold text-left py-2">Term Start</th>
						<th className="text-xs font-bold text-left py-2">Term End</th>
						<th className="text-xs font-bold text-left py-2"></th>
					</tr>
				</thead>
				
				<tfoot className="pdf-hide">
					<tr>
						<td className="py-2" colSpan={ 2 }>
							<button
								type="button"
								className="text-sm text-rural-red-900 font-semibold"
								onClick={ () => {
									append({ name: '', policyNumber: '', termStart: '', termEnd: '' });
									
									setAddedFields( addedFields + 1 );
								}}
							>
								&#x2b; Add a policy/line of business
							</button>
						</td>
						<td colSpan={ 3 }></td>
					</tr>
				</tfoot>
				
				<tbody>
					{ initialValues.map( ( initial: any ) => (
						<tr key={ initial.key } className="[&>td]:border-b [&>td]:border-rural-gray-500">
							<td className="text-sm font-normal py-2 pr-8">{ initial.label }</td>
							<td className="text-sm font-normal py-2 pr-8">
								<Text
									systemId={ `${ systemId }-initial.${ initial.key }.policyNumber` }
									label="Policy number"
									hideLabel={ true }
									size="mini"
									type="text"
								/>
							</td>
							<td className="text-sm font-normal py-2 pr-8">
								<Date
									systemId={ `${ systemId }-initial.${ initial.key }.termStart` }
									label="Term start"
									size="mini"
									hideLabel={ true }
									required={ false }
								/>
							</td>
							<td className="text-sm font-normal py-2 pr-8">
								<Date
									systemId={ `${ systemId }-initial.${ initial.key }.termEnd` }
									label="Term end"
									size="mini"
									hideLabel={ true }
									required={ false }
								/>
							</td>
							<td className="text-sm font-normal py-2"></td>
						</tr>
					))}

					{ fields.map( ( field, index ) => (
						<tr key={ field.id } className="[&>td]:border-b [&>td]:border-rural-gray-500">
							<td className="text-sm font-normal py-2 pr-8">
								<Text
									systemId={ `${ systemId }.${ index }.name` }
									label="Policy/Line of business"
									hideLabel={ true }
									size="mini"
									type="text"
									required={ true }
								/>

								<Error
									id={ `${ systemId }.${ index }.name` }
									errors={ errors }
									className="mt-1 text-xs"
								/>
							</td>
							<td className="text-sm font-normal py-2 pr-8">
								<Text
									systemId={ `${ systemId }.${ index }.policyNumber` }
									label="Policy/Line of business"
									hideLabel={ true }
									size="mini"
									type="text"
									required={ true }
								/>

								<Error
									id={ `${ systemId }.${ index }.policyNumber` }
									errors={ errors }
									className="mt-1 text-xs"
								/>
							</td>
							<td className="text-sm font-normal py-2 pr-8">
								<Date
									systemId={ `${ systemId }.${ index }.termStart` }
									label="Term end"
									size="mini"
									hideLabel={ true }
								/>

								<Error
									id={ `${ systemId }.${ index }.termStart` }
									errors={ errors }
									className="mt-1 text-xs"
								/>
							</td>
							<td className="text-sm font-normal py-2 pr-8">
								<Date
									systemId={ `${ systemId }.${ index }.termEnd` }
									label="Term end"
									size="mini"
									hideLabel={ true }
								/>

								<Error
									id={ `${ systemId }.${ index }.termEnd` }
									errors={ errors }
									className="mt-1 text-xs"
								/>
							</td>
							<td className="text-sm font-normal p-2 text-center">
								<button
									type="button"
									className="text-rural-red-900 font-bold"
									onClick={ () => {
										remove( index );

										setAddedFields( addedFields - 1 );
									}}
								>
									&times;
								</button>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
}
