import AdditionalQuestions from '@/components/AdditionalQuestions';

type QuestionTableProps = {
	questions: any[];
	shouldHideNoResponses?: boolean;
	hideNoResponses?: boolean;
};

export default function QuestionTable({
	questions,
	shouldHideNoResponses,
	hideNoResponses
}: QuestionTableProps ) {
	return (
		<div className="mb-4 border-t border-rural-gray-500">
			{ questions.length > 0 && (
				<AdditionalQuestions
					questions={ questions }
					marginBottom={ false }
					shouldHideNoResponses={ hideNoResponses || shouldHideNoResponses }
				/>
			)}
		</div>
	);
}
