import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';

type CharRemainingProps = {
	charLimit: number;
	systemId: string;
};

function CharacterCount({
	charLimit,
	systemId
}: CharRemainingProps ) {
	const textValue = useWatch({ name: systemId });

	const currentLength = useMemo( () => {
		return charLimit - ( textValue?.length || 0 );
	}, [ charLimit, textValue ] );

	const textColor = useMemo( () => {
		return currentLength < 0 ? 'text-rural-red-900' : 'text-rural-gray-700';
	}, [ currentLength ] );

	return (
		<div className={ `${ textColor } font-light text-xs mb-2 pdf-hide` }>
			{ currentLength }/{ charLimit } characters
		</div>
	);
}

export default function CharRemaining( props: CharRemainingProps ) {
	if ( props.charLimit <= 0 ) {
		return null;
	}

	return <CharacterCount { ...props } />
}
