import { apiCall } from '@/services/webservices/shared';

export async function getForms() {
	return apiCall( '/Forms' );
}

export async function getForm( formId: string ) {
	return apiCall( `/Forms/${ formId }` );
}

export async function activateOrInactivateForm( cppId: string, formId: string, activationStatus: boolean ) {
	return apiCall( `/CppApplicationForm/form/changestatus?cppApplicationId=${ cppId }&applicationFormId=${ formId }&bInactivate=${ activationStatus }`, {
		method: 'POST'
	}, 'There was an error changing the activation status of this form', false );
}

export async function createCppForm( cppId: string, formTypeId: number, formBody = {} ): Promise<ApplicationForm> {
	return apiCall( `/CppApplicationForm?cppApplicationId=${ cppId }&formTypeId=${ formTypeId }`, {
		method: 'POST',
		body: JSON.stringify( formBody ),
		headers: {
			'Content-Type': 'application/json'
		}
	}, 'There was an error creating an application form' );
}

export async function deleteCppForm( cppId: string, formId: string ) {
	return apiCall( `/CppApplicationForm?cppApplicationId=${ cppId }&formId=${ formId }`, {
		method: 'DELETE'
	}, 'There was an error deleting this form', false );
}

export async function updateCppForm( cppId: string, formId: string, formBody = {}, isDraft = false ): Promise<ApplicationForm> {
	return apiCall( `/CppApplicationForm/update/form?cppApplicationId=${ cppId }&formId=${ formId }&isDraft=${ isDraft }`, {
		method: 'POST',
		body: JSON.stringify( formBody ),
		headers: {
			'Content-Type': 'application/json'
		}
	}, 'There was an error updating this form' );
}

export async function updateCppFormWithStatus( cppId: string, formId: string, formBody = {} ): Promise<{status: number}> {
	return apiCall( `/CppApplicationForm/update/form?cppApplicationId=${ cppId }&formId=${ formId }`, {
		method: 'POST',
		body: JSON.stringify( formBody ),
		headers: {
			'Content-Type': 'application/json'
		}
	}, 'There was an error updating this form', false );
}