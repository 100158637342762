import type { FC } from 'react';

import { useEffect, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';

import { useCpp } from '@/hooks/useCpp';
import { useCppForm } from '@/hooks/useCppForm';
import { useIsExporting } from '@/hooks/useIsExporting';
import { getDateFromSquidex, compareDates, toDateTime, unserializeDate } from '@/services/dates';
import { filterUniqueStrings } from '@/services/utilities';

interface ValidatedComponentProps {
	OriginalComponent: FC<any>,
	validators: Validator[];
	question: AnyQuestion;
	shouldHideNoResponses?: boolean;
}

const useFormValues = ( ids: string[] ) => {
	const { getValues } = useFormContext();

	return {
		...useWatch({ name: ids }),
		...getValues()
	};
}

// Per B-107430, WRK dropdowns should always be hidden from blank PDF export.
// [TODO]: If this needs to happen more in the future, we should add an option
// to the Squidex schema that specifies an item should always be hidden in blank PDFs
const idsToAlwaysHide = [
	'C2392.excess.scheduleUnderlyingInsuranceCoverages.wrkLimits',
	'C2391.scheduleUnderlyingInsuranceCoverages.wrkLimits'
];

function getFieldValue( value: any ) {
	if ( !value ) {
		return '';
	}

	if ( typeof value === 'string' || Array.isArray( value ) ) {
		return value;
	}

	// otherwise, it's an object from a select { label: '', value: '' }
	if ( typeof value === 'object' ) {
		return value.value;
	}

	return '';
}

function isMatchedValue( matchedValue: any, comparisonValue: any ) {
	if ( Array.isArray( matchedValue ) ) {
		return matchedValue.includes( comparisonValue );
	}

	return isEqual( matchedValue, comparisonValue )
}

function isInEffectiveRange( validator: ValidationEffectiveDate, effectiveDate: string ): boolean {
	return compareDates( getDateFromSquidex( validator.date ), unserializeDate( effectiveDate ), validator.timePeriod );
}

export default function ValidatedComponent({
	OriginalComponent,
	validators,
	question,
	shouldHideNoResponses
}: ValidatedComponentProps ) {
	const { systemId, title } = question as any;

	const { cppData } = useCpp();
	const { sectionHiders } = useCppForm();
	const { unregister } = useFormContext();
	const { isBlankExport, isExporting, exportEffectiveDate } = useIsExporting();

	const effectiveDateValidators: ValidationEffectiveDate[] = useMemo( () => {
		return validators.filter( validator => validator.fieldType === 'EffectiveDateComparison' ) as ValidationEffectiveDate[];
	}, [ validators ] );

	const comparisonValidators: ValidationCompare[] = useMemo( () => {
		return validators.filter( validator => validator.fieldType === 'ValueComparison' ) as ValidationCompare[];
	}, [ validators ] );


	// 1. Get a list of IDs that need to have their values checked
	const idsToWatch = useMemo( () => {
		return comparisonValidators
			.map( validator => validator.comparisons )
			.flat()
			.map( comparison => comparison.systemId )
			.filter( filterUniqueStrings );
	}, [ comparisonValidators ] );

	const watchedValues = useFormValues( idsToWatch );

	const watchedObject = useMemo( () => {
		let returnObject: Record<string, any> = {};

		idsToWatch.forEach( ( systemId ) => {
			returnObject[ systemId ] = getFieldValue( get( watchedValues, systemId, '' ) );
		});

		return returnObject;
	}, [ watchedValues, idsToWatch ] );


	// 2. Loop through validators and determine visibility status
	const effectiveDateCheck = useMemo( () => {
		if ( effectiveDateValidators.length === 0 ) {
			return undefined;
		}

		return effectiveDateValidators.every( validator => {
			let comparisonDate = exportEffectiveDate ? toDateTime( exportEffectiveDate ) : cppData.quoteEffectiveDate;

			return isInEffectiveRange( validator, comparisonDate );
		});
	}, [ cppData.quoteEffectiveDate, effectiveDateValidators, exportEffectiveDate ] );

	const watchedValuesCheck = useMemo( () => {
		if ( comparisonValidators.length === 0 ) {
			return undefined;
		}

		const check = comparisonValidators
			.map( validator => {
				if ( validator.requireEvery ) {
					return validator.comparisons.every( comparison => {
						const matchedValue = watchedObject[ comparison.systemId ];

						return isMatchedValue( matchedValue, comparison.value );
					});
				} else {
					return validator.comparisons.some( comparison => {
						const matchedValue = watchedObject[ comparison.systemId ];

						return isMatchedValue( matchedValue, comparison.value );
					});
				}
			});

		return check.some( a => a );
	}, [ watchedObject, comparisonValidators ] );

	const isHidden = useMemo( () => {
		return [ effectiveDateCheck, watchedValuesCheck ].filter( a => a !== undefined ).some( a => !a );
	}, [ effectiveDateCheck, watchedValuesCheck ] );


	// 3. See if it needs to be hidden in exports
	// Per B-107430, WRK dropdowns should always be hidden from blank PDF export.
	// [TODO]: If this needs to happen more in the future, we should add an option
	// to the Squidex schema that specifies an item should always be hidden in blank PDFs
	const isAlwaysHiddenInExports = useMemo( () => {
		if ( !systemId ) {
			return false;
		}

		if ( idsToAlwaysHide.includes( systemId.replace( /\[\d{1,}\]/, '' ) ) ) {
			return true;
		}

		return false;
	}, [ systemId ] );


	// 4. Set sidebar visibility
	useEffect( () => {
		if ( !title || !sectionHiders ) {
			return;
		}

		if ( !isBlankExport ) {
			const sectionTitlesFunction = sectionHiders.get( title );
	
			if ( !!sectionTitlesFunction ) {
				sectionTitlesFunction( isHidden );
			}
		}
	}, [ isBlankExport, isHidden, sectionHiders, title ] );


	// 5. If the field changes visibility, see if we need to reset the value of it
	useEffect( () => {
		if ( isHidden && systemId && !isExporting ) {
			unregister( systemId );
		}
	}, [ isExporting, isHidden, systemId, unregister ] );


	if ( ( isBlankExport && isAlwaysHiddenInExports ) || ( isBlankExport && effectiveDateCheck === false ) || ( !isBlankExport && isHidden ) ) {
		return null;
	}

	return (
		<OriginalComponent
			{ ...question }
			shouldHideNoResponses={ shouldHideNoResponses }
		/>
	);
}
