import type { ChangeEvent } from 'react';

import { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Text } from '@/components/FormElements';
import Grid, { Column } from '@/components/Grid';

import { useCpp } from '@/hooks/useCpp';
import { useIsExporting } from '@/hooks/useIsExporting';

type SyncedAddressProps = {
	systemId: string;
};

export default function SyncedAddress({
	systemId
}: SyncedAddressProps ) {
	const { getValues, setValue } = useFormContext();
	const { isExporting } = useIsExporting();

	const { cppData } = useCpp();

	const [ usingSavedAddress, setUsingSavedAddress ] = useState( false );

	useEffect( () => {
		// check to see if the location address matches the CPP address
		const { streetAddress, city, state, zipCode } = cppData;
		const { streetAddress: locationStreetAddress, city: locationCity, state: locationState, zipCode: locationZipCode } = getValues( systemId ) || {};

		if ( streetAddress !== locationStreetAddress || city !== locationCity || state !== locationState || zipCode !== locationZipCode ) {
			setUsingSavedAddress( false );
		} else {
			setUsingSavedAddress( true );
		}
	}, [ cppData, getValues, setUsingSavedAddress, systemId ] );

	const handleOnChange = useCallback( ( event: ChangeEvent<HTMLInputElement> ) => {
		if ( event.target.checked ) {
			setValue( `${ systemId }.streetAddress`, cppData.streetAddress );
			setValue( `${ systemId }.city`, cppData.city );
			setValue( `${ systemId }.state`, cppData.state );
			setValue( `${ systemId }.zipCode`, cppData.zipCode );

			setUsingSavedAddress( true );
		} else {
			setUsingSavedAddress( false );
		}
	}, [ cppData, setUsingSavedAddress, setValue, systemId ] );

	return (
		<Grid align="top" className="gap-x-4 my-2">
			<Column columns={ 8 } className="mb-4">
				<Text
					label="Street address (number/name) or legal description (use county, state road, or fire number)"
					systemId={ `${ systemId }.streetAddress` }
					required={ true }
					type="text"
					width="full"
					disabled={ usingSavedAddress }
				/>
			</Column>

			<Column columns={ 4 } />

			<Column columns={ 4 } className="mb-4">
				<Text
					label="City/Town"
					systemId={ `${ systemId }.city` }
					required={ true }
					type="text"
					width="full"
					disabled={ usingSavedAddress }
				/>
			</Column>

			<Column columns={ 4 } className="mb-4">
				<Text
					label="State"
					systemId={ `${ systemId }.state` }
					required={ true }
					type="text"
					width="full"
					disabled={ usingSavedAddress }
				/>
			</Column>

			<Column columns={ 4 } />

			<Column columns={ 4 } className="mb-4">
				<Text
					label="Zip code"
					systemId={ `${ systemId }.zipCode` }
					required={ true }
					type="text"
					width="full"
					disabled={ usingSavedAddress }
				/>
			</Column>

			{ !isExporting && cppData?.streetAddress && (
				<>
					<Column columns={ 8 } />

					<Column columns={ 6 }>
						<label className="text-sm">
							<input
								type="checkbox"
								value="true"
								checked={ usingSavedAddress }
								onChange={ handleOnChange }
							/>{ ' ' }

							Mailing Address and Property Address are the same
						</label>
					</Column>
				</>
			)}
		</Grid>
	);
}
