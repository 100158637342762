export function accumulateValues( questions: any[], cb: Function ) {
	questions.forEach( ( question: any ) => {
		cb( question );

		const additional = question.additionalQuestions || question.additionalquestions || question.additionalYes || question.additionalNo || question.questions;

		if ( additional ) {
			accumulateValues( additional, cb );
		}

		if ( question.fieldType === 'Select' ) {
			accumulateValues( question.options.map( ( option: any ) => {
				return {
					...option,
					fieldType: 'SelectOption',
					parentLabel: question.label
				};
			}), cb );
		}

		if ( question.fieldType === 'Radio' ) {
			question.radioButtons.forEach( ( button: any ) => {
				if ( button.additionalQuestions ) {
					accumulateValues( button.additionalQuestions, cb );
				}
			});
		}
	});
}

export function mapValues( questions: any[], cb: Function ) {
	return questions.map( ( question: any ) => {
		let copiedQuestion = { ...question };

		cb( copiedQuestion );

		if ( copiedQuestion.questions ) {
			copiedQuestion.questions = mapValues( copiedQuestion.questions, cb );
		}

		if ( copiedQuestion.additionalQuestions ) {
			copiedQuestion.additionalQuestions = mapValues( copiedQuestion.additionalQuestions, cb );
		}

		if ( copiedQuestion.additionalquestions ) {
			copiedQuestion.additionalquestions = mapValues( copiedQuestion.additionalquestions, cb );
		}

		if ( copiedQuestion.additionalYes ) {
			copiedQuestion.additionalYes = mapValues( copiedQuestion.additionalYes, cb );
		}

		if ( copiedQuestion.additionalNo ) {
			copiedQuestion.additionalNo = mapValues( copiedQuestion.additionalNo, cb );
		}

		if ( copiedQuestion.fieldType === 'Select' ) {
			copiedQuestion.options = copiedQuestion.options.map( ( option: any ) => {
				return {
					...option,
					additionalQuestions: mapValues( option.additionalQuestions, cb )
				};
			});
		}

		if ( copiedQuestion.fieldType === 'Radio' ) {
			copiedQuestion.radioButtons = copiedQuestion.radioButtons.map( ( button: any ) => {
				return {
					...button,
					additionalQuestions: mapValues( button.additionalQuestions, cb )
				};
			});
		}

		return copiedQuestion;
	});
}
