import type { ReactNode } from 'react';

type HeaderLinkProps = {
	href?: string;
	children: ReactNode;
	isHiddenInPdf?: boolean;
};

export default function HeaderLink({
	href,
	children,
	isHiddenInPdf = true
}: HeaderLinkProps ) {
	if ( !href ) {
		return null;
	}

	return (
		<a href={ href } className="text-sm text-blue-500 underline inline-block ml-3 font-normal" target="_blank" rel="noopener noreferrer"><span className={ isHiddenInPdf ? 'pdf-hide' : undefined }>{ children }</span></a>
	);
}
