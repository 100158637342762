import type { ReactElement, ReactNode } from 'react';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';

import Head from 'next/head';
import Script from 'next/script';
import { CookiesProvider } from 'react-cookie';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import Modal from '@/components/Modal';

import { DrawersProvider } from '@/hooks/useDrawers';
import { FiltersProvider } from '@/hooks/useFilters';
import { MeProvider } from '@/hooks/useMe';
import { ModalProvider } from '@/hooks/useModal';
import { ThemeProvider } from '@/hooks/useTheme';

import Main from '@/layouts/main';

import '@/styles/globals.css';

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
	getLayout?: ( page: ReactElement ) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
	Component: NextPageWithLayout;
};

const bugherdKey = process.env.NEXT_PUBLIC_BUGHERD_KEY || '';

const queryClient = new QueryClient();

function CppApp({ Component, pageProps }: AppPropsWithLayout ) {
	const getLayout = Component.getLayout ?? ( ( page ) => page );

	return (
		<>
			<Head>
				<link rel="icon" href="/favicon.ico" />
				<link rel="icon" type="image/png" sizes="32x32" href="/images/favicon-32x32.png" />
				<link rel="icon" type="iamge/png" sizes="16x16" href="/images/favicon-16x16.png" />

			</Head>

			{ bugherdKey && (
				<Script
					src={ `https://www.bugherd.com/sidebarv2.js?apikey=${ bugherdKey }` }
					strategy="lazyOnload"
				/>
			)}
			
			<QueryClientProvider client={ queryClient }>
				<MeProvider>
					<CookiesProvider>
						<ModalProvider>
							<ThemeProvider>
								<DrawersProvider>
									<FiltersProvider>
										<Main>
											{ getLayout( <Component {...pageProps} /> ) }

											<Modal />
										</Main>
									</FiltersProvider>
								</DrawersProvider>
							</ThemeProvider>
						</ModalProvider>
					</CookiesProvider>
				</MeProvider>
			</QueryClientProvider>
		</>
	);
}

export default CppApp;
