import { useEffect, useState } from 'react';

import { Select } from '@/components/FormElements';

import { getAgents } from '@/services/webservices/cpp';

export default function AgentSelect() {
	const [ agentOptions, setAgentOptions ] = useState( [] );

	useEffect( () => {
		async function getAgentOptions() {
			const agents = await getAgents();

			setAgentOptions( agents );
		}

		getAgentOptions();
	}, [ setAgentOptions ] );

	return (
		<Select
			options={ agentOptions }
			systemId="associatedAgents"
			placeholder="Select an agent..."
			label="Select an agent"
			hideLabel={ true }
			isInCpp={ false }
		/>
	);
}
