import type { ChangeEvent } from 'react';

import { useCallback, useEffect, useMemo, useState } from 'react';
import { IMaskInput } from 'react-imask';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import Button from '@/components/Button';
import { Error, RequiredIndicator } from '@/components/Form';

import { OccurrenceFormProvider, useOccurrenceForm } from '@/hooks/useOccurrenceForm';

import { columnMap, columns, defaultState, rowIndexMap } from '@/definitions/occurrenceDefaults';

type OccurrenceFormProps = {
	systemId: string;
};

type CurrencyProps = {
	cellKey: string;
	columnIndex: number;
	inputId: string;
	value: string;
};

type OccurrenceSelectProps = {
	cellKey: string;
	columnIndex: number;
	inputId: string;
	options: SelectOption[];
};

type ReadOnlyProps = {
	inputId: string;
	valueLabel: string;
};

type CellInputProps = {
	cellValue: string;
	columnIndex: number;
	inputId: string;
	options?: SelectOption[];
	rowKey: string;
	valueLabel?: string;
};

type CellProps = {
	className: string;
	cellValue: string;
	columnIndex: number;
	onClick: any;
	onMouseOver: any;
	onMouseOut: any;
	row: any;
};

type ColumnProps = {
	column: any;
	columnIndex: number;
};

function Currency({
	cellKey,
	columnIndex,
	inputId,
	value
}: CurrencyProps ) {
	const { control, setValue, updateCellValue } = useOccurrenceForm();

	const updateValues = useCallback( ( val: any, fieldCallback: Function ) => {
		if ( cellKey === 'general' ) {
			setValue( `occurrencesub.${ columnIndex }.products`, val );

			updateCellValue( columnIndex, 'products', val );
		}

		updateCellValue( columnIndex, cellKey, val );
		
		fieldCallback( val );
	}, [ cellKey, columnIndex, setValue, updateCellValue ] );

	return (
		<div className="input-currency bg-white max-w-[85px]">
			<Controller
				name={ inputId }
				control={ control }
				rules={{ required: true }}
				defaultValue={ value }
				render={ ({ field }) => (
					<IMaskInput
						// @ts-ignore
						id={ inputId }
						disabled={ cellKey === 'products' }
						className="py-1 pr-1 border bg-transparent border-rural-gray-500 w-full placeholder:text-rural-gray-400 disabled:opacity-50"
						mask={ Number }
						overwrite={ true }
						lazy={ true }
						scale={ 2 }
						radix="."
						placeholderChar="#"
						thousandsSeparator=","
						padFractionalZeros={ false }
						unmask={ true }
						value={ field.value }
						onAccept={ ( val ) => { updateValues( val, field.onChange ) } }
					/>
				)}
			/>
		</div>
	);
}

function OccurrenceSelect({
	cellKey,
	columnIndex,
	inputId,
	options
}: OccurrenceSelectProps ) {
	const { control, setValue, updateCellValue } = useOccurrenceForm();

	const handleOnChange = useCallback( ( e: ChangeEvent<HTMLSelectElement>, fieldCallback: ( e: ChangeEvent<HTMLSelectElement> ) => void ) => {
		if ( cellKey === 'general' ) {
			setValue( `occurrencesub.${ columnIndex }.products`, e.target.value );

			updateCellValue( columnIndex, 'products', e.target.value );
		}

		updateCellValue( columnIndex, cellKey, e.target.value );

		fieldCallback( e );
	}, [ cellKey, columnIndex, setValue, updateCellValue ] );

	return (
		<Controller
			name={ inputId }
			control={ control }
			rules={{ required: true }}
			render={ ({ field }) => (
				<select
					disabled={ cellKey === 'products' }
					id={ inputId }
					className="p-1 border border-rural-gray-500 bg-white disabled:opacity-50"
					onChange={ ( e ) => handleOnChange( e, field.onChange ) }
					value={ field.value }
				>
					{ options.map( ( option: any ) => (
						<option key={ option.value } value={ option.value }>{ option.label }</option>
					))}
				</select>
			)}
		/>
	);
}

function ReadOnly({
	inputId,
	valueLabel
}: ReadOnlyProps ) {
	const { register } = useOccurrenceForm();

	return (
		<>
			<input
				{ ...register( inputId, { required: true } ) }
				type="hidden"
			/>

			{ valueLabel }
		</>
	);
}

function CheckMark() {
	return (
		<div className="-translate-y-5">
			<img
				src="/images/checkmark.svg"
				className="mx-auto"
				width={ 40 }
				height={ 40 }
				alt="Selected"
			/>
		</div>
	);
}

function LabelColumn() {
	return (
		<>
			<div className="col-start-1 px-4 !py-0 h-[15px] row-start-1"></div>

			<div className="col-start-1 py-2 row-start-2">Each Occurrence</div>

			<div className="col-start-1 py-2 row-start-3">General Aggregate</div>

			<div className="col-start-1 py-2 row-start-4">Products &amp; Completed Operations Aggregate</div>

			<div className="col-start-1 py-2 row-start-5">Personal &amp; Advertising Injury</div>

			<div className="col-start-1 py-2 row-start-6">Damage to Premises Rented to You</div>

			<div className="col-start-1 py-2 row-start-7">Medical Expenses</div>

			<div className="col-start-1 row-start-8"></div>
		</>
	);
}

function CellInput({
	cellValue,
	columnIndex,
	inputId,
	options,
	rowKey,
	valueLabel
}: CellInputProps ) {
	if ( options && options.length > 0 ) {
		return (
			<OccurrenceSelect
				cellKey={ rowKey }
				columnIndex={ columnIndex }
				inputId={ inputId }
				options={ options }
			/>
		);
	}

	if ( valueLabel ) {
		return (
			<ReadOnly
				inputId={ inputId }
				valueLabel={ valueLabel }
			/>
		);
	}

	return (
		<Currency
			inputId={ inputId }
			cellKey={ rowKey }
			columnIndex={ columnIndex }
			value={ cellValue }
		/>
	);
}

function Cell({
	className,
	cellValue,
	columnIndex,
	onClick,
	onMouseOver,
	onMouseOut,
	row
}: CellProps ) {
	const { rowIndex, options, valueLabel } = row;

	const inputId = `occurrencesub.${ columnIndex }.${ row.rowKey }`;

	return (
		<div
			className={ `${ className } ${ rowIndexMap[ rowIndex ] }` }
			onMouseOver={ onMouseOver }
			onMouseOut={ onMouseOut }
			onClick={ onClick }
		>
			<CellInput
				cellValue={ cellValue }
				columnIndex={ columnIndex }
				inputId={ inputId }
				options={ options }
				rowKey={ row?.rowKey }
				valueLabel={ valueLabel }
			/>
		</div>
	);
}

function Column({
	column,
	columnIndex
}: ColumnProps ) {
	const {
		tableValues,
		selectedColumn,
		setColumnValue
	} = useOccurrenceForm();

	const { columnIndex: configIndex, rows } = column;

	const columnValues = tableValues[ columnIndex ];

	const [ isHovered, setIsHovered ] = useState( false );
	const isSelected = useMemo( () => selectedColumn === configIndex, [ selectedColumn, configIndex ] );
	
	// state-based classes
	const selectedClass = isSelected ? 'bg-rural-red-100 font-bold' : '';
	const hoveredClass = isHovered && !isSelected ? 'bg-rural-red-50' : '';

	const baseClass = useMemo( () => {
		return `${ columnMap[ configIndex ] } ${ selectedClass } ${ hoveredClass }`
	}, [ configIndex, selectedClass, hoveredClass ] );
	
	return (
		<>
			<div className={ `${ baseClass } px-4 !py-0 h-[15px] text-center row-start-1` }>
				{ isSelected && <CheckMark /> }
			</div>

			{ rows.map( ( row: any ) => (
				<Cell
					key={ row.rowIndex }
					className={ baseClass }
					row={ row }
					cellValue={ columnValues ? columnValues[ row.rowKey ] : null }
					columnIndex={ columnIndex }
					onClick={ ( e: any ) => {
						if ( e.target.nodeName === 'DIV' ) {
							setColumnValue( configIndex );
						}
					}}
					onMouseOver={ () => setIsHovered( true ) }
					onMouseOut={ () => setIsHovered( false ) }
				/>
			))}

			<div
				className={ `${ baseClass } row-start-8 text-center pdf-hide` }
				onMouseOver={ () => setIsHovered( true ) }
				onMouseOut={ () => setIsHovered( false ) }
			>
				<Button
					type="button"
					className={ isHovered ? '!bg-rural-red-900 !text-white !border-rural-red-900' : '' }
					variant="border"
					size="mini"
					onClick={ () => {
						setColumnValue( configIndex );
					}}
				>
					{ configIndex === selectedColumn ? 'Deselect' : 'Select' }
				</Button>
			</div>
		</>
	);
}

function OccurrenceFormData({
	systemId
}: OccurrenceFormProps ) {
	const { setValue } = useOccurrenceForm();

	const currentValues = useWatch({
		name: systemId
	});

	useEffect( () => {
		let updatedValues = { ...defaultState };

		if ( currentValues?.columnIndex ) {
			updatedValues[ currentValues.columnIndex - 2 ] = currentValues.tableValues;
		}

		setValue( 'occurrencesub', updatedValues );
	}, [ currentValues, setValue ] );

	return (
		<div className="grid grid-rows-7 grid-cols-6 text-xs">
			<LabelColumn />

			{ columns.map( ( column: any, index: number ) => (
				<Column
					key={ column.columnIndex }
					column={ column }
					columnIndex={ index }
				/>
			))}
		</div>
	);
}

export default function OccurrenceForm({
	systemId
}: OccurrenceFormProps ) {
	const { control, formState: { errors }, setValue } = useFormContext();

	return (
		<Controller
			name={ systemId }
			control={ control }
			rules={{ required: true }}
			render={ ({ field: { ref } }) => (
				<OccurrenceFormProvider
					setValueWithParent={ setValue }
					systemId={ systemId }
				>
					<div className="my-8" ref={ ref } tabIndex={ 0 }>
						<div className="flex gap-x-2">
							<RequiredIndicator />

							<Error
								errors={ errors }
								id={ systemId }
							/>
						</div>

						<OccurrenceFormData systemId={ systemId } />
					</div>
				</OccurrenceFormProvider>
			)}
		/>
	);
}
