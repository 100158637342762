import ElementLoader from '@/components/ElementLoader';

type AdditionalQuestionsProps = {
	questions: any[];
	marginBottom?: boolean;
	shouldHideNoResponses?: boolean;
};

export default function AdditionalQuestions({
	questions = [],
	marginBottom = true,
	shouldHideNoResponses
}: AdditionalQuestionsProps ) {
	if ( questions.length === 0 ) {
		return null;
	}

	return (
		<>
			{ questions.map( ( question: any, index: number ) => (
				<ElementLoader
					key={ index }
					question={ question }
					marginBottom={ marginBottom && question.fieldType !== 'YesNo' }
					shouldHideNoResponses={ shouldHideNoResponses }
				/>
			))}
		</>
	);
}
