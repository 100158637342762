import type { ReactNode } from 'react';

type ColumnCount = IntRange<1, 13>;
type Alignment = 'center' | 'top' | 'bottom';

type GridProps = {
	children: ReactNode;
	align?: Alignment;
	className?: string;
	gap?: ColumnCount;
};

type ColumnProps = {
	children?: ReactNode;
	columns: ColumnCount;
	className?: string;
};

// this only exists because Tailwind is kind of weird.
const gridClassMap: Record<ColumnCount, string> = {
	1: 'col-span-1',
	2: 'col-span-2',
	3: 'col-span-3',
	4: 'col-span-4',
	5: 'col-span-5',
	6: 'col-span-6',
	7: 'col-span-7',
	8: 'col-span-8',
	9: 'col-span-9',
	10: 'col-span-10',
	11: 'col-span-11',
	12: 'col-span-12'
};

const gapClassMap: Record<ColumnCount, string> = {
	1: 'gap-x-1',
	2: 'gap-x-2',
	3: 'gap-x-3',
	4: 'gap-x-4',
	5: 'gap-x-5',
	6: 'gap-x-6',
	7: 'gap-x-7',
	8: 'gap-x-8',
	9: 'gap-x-9',
	10: 'gap-x-10',
	11: 'gap-x-11',
	12: 'gap-x-12'
};

const alignClassMap: Record<Alignment, string> = {
	center: 'items-center',
	top: 'items-top',
	bottom: 'items-bottom'
};

export function Column({
	children,
	columns,
	className = ''
}: ColumnProps ) {
	return (
		<div className={ `${ gridClassMap[ columns ] } ${ className }` }>
			{ children }
		</div>
	);
}

export default function Grid({
	children,
	align = 'center',
	className = '',
	gap
}: GridProps ) {
	const alignClass = alignClassMap[ align ] || 'items-center';
	const gapClass = ( gap ? gapClassMap[ gap ] : '' ) || '';

	return (
		<div className={ `grid grid-cols-12 ${ alignClass } ${ className } ${ gapClass }` }>
			{ children }
		</div>
	);
}
