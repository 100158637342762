import { useMemo } from 'react';
import ReactModal from 'react-modal';
import { FormProvider, useForm } from 'react-hook-form';
import merge from 'lodash/merge';

import AgentSearch from '@/components/Modals/AgentSearch';
import ChangeEffectiveDate from '@/components/Modals/ChangeEffectiveDate';
import CreateCpp from '@/components/Modals/CreateCpp';
import ConfirmApplicationDelete from '@/components/Modals/ConfirmApplicationDelete';
import ConfirmBuildingUpdate from '@/components/Modals/ConfirmBuildingUpdate';
import ConfirmCppDelete from '@/components/Modals/ConfirmCppDelete';
import ConfirmDelete from '@/components/Modals/ConfirmDelete';
import UnderwriterSearch from '@/components/Modals/UnderwriterSearch';

import { IsExportingProvider } from '@/hooks/useIsExporting';
import { useModal } from '@/hooks/useModal';
import { useMe } from '@/hooks/useMe';
import { isAgentChangeAllow } from '@/definitions/userRoles';

ReactModal.setAppElement( '#__next' );

const customStyles = {
	content: {
		zIndex: '70',
		maxWidth: '600px',
		top: '50%',
		right: '0',
		bottom: 'auto',
		left: '0',
		margin: 'auto',
		padding: '35px',
		transform: 'translateY(-50%)',
		overflow: 'visible'
	},
	overlay: {
		zIndex: '60',
		backgroundColor: 'rgba(255, 255, 255, 0.9)'
	}
};

export default function Modal() {
	const {
		cancel,
		proceed,
		isOpen = false,
		modalData,
		modalStyles,
		type
	}: any = useModal();

	const methods = useForm();
	const{ role } = useMe();

	const appliedStyles = useMemo( () => {
		return merge( customStyles, modalStyles );
	}, [ modalStyles ] );

	return (
		<ReactModal
			isOpen={ isOpen }
			onRequestClose={ cancel }
			style={ appliedStyles }
		>
			<IsExportingProvider isPdfExport={ false }>
				<FormProvider { ...methods }>
					{ type === 'changeeffectivedate' && (
						<ChangeEffectiveDate
							currentCppEffectiveDate={ modalData?.currentCppEffectiveDate }
							title={ modalData?.title }
							onSuccess={ modalData?.onSuccess }
							onCancel={ cancel }
							onCheck={ modalData?.onCheck }
						/>
					)}

					{ type === 'createcpp' && (
						<CreateCpp
							isMidterm={ modalData?.isMidterm }
							onClose={ cancel }
							onConfirm={ modalData?.onConfirm }
						/>
					)}

					{ type === 'agentsearch' && isAgentChangeAllow( role ) && (
						<AgentSearch
							cppId={ modalData?.cppId }
							agentOptions={ modalData?.agentOptions }
							onCancel={ cancel }
							onSuccess={ modalData?.onSuccess }
						/>
					)}

					{ type === 'underwritersearch' && (
						<UnderwriterSearch
							onCancel={ cancel }
							cppId={ modalData?.cppId }
							onSuccess={ modalData?.onSuccess }
						/>
					)}

					{ type === 'confirmdelete' && (
						<ConfirmDelete
							onCancel={ cancel }
							onConfirm={ proceed }
							itemCount={ modalData?.itemCount }
						/>
					)}

					{ type === 'confirmcppdelete' && (
						<ConfirmCppDelete
							onCancel={ cancel }
							onConfirm={ proceed }
							displayId={ modalData?.displayId }
						/>
					)}

					{ type === 'confirmapplicationdelete' && (
						<ConfirmApplicationDelete
							applicationName={ modalData?.applicationName }
							onCancel={ cancel }
							onConfirm={ proceed }
						/>
					)}

					{ type === 'confirmbuildingupdate' && (
						<ConfirmBuildingUpdate
							onCancel={ cancel }
							onConfirm={ proceed }
						/>
					)}
				</FormProvider>
			</IsExportingProvider>
		</ReactModal>
	);
}
