import type { ReactNode } from 'react';

import RequiredIndicator from './RequiredIndicator';

type LabelProps = {
	children: ReactNode;
	className?: string;
	errors?: ReactNode;
	htmlFor?: string;
	isHidden?: boolean;
	isRequired?: boolean;
	hideRequired?: boolean;
};

export default function Label({
	children,
	className,
	errors,
	htmlFor,
	isHidden = false,
	isRequired = false,
	hideRequired = false
}: LabelProps ) {
	return (
		<label htmlFor={ htmlFor } className={ `text-sm ${ className }` }>
			<div className="flex gap-x-1">
				<span className={ isHidden ? 'sr-only' : '' }>
					{ children }
				</span>
				
				{ isRequired && !hideRequired && (
					<RequiredIndicator />
				)}
			</div>

			{ errors }
		</label>
	);
}
