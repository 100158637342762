import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import ElementLoader from '@/components/ElementLoader';

import { useIsExporting } from '@/hooks/useIsExporting';

function MultiItemSubrow({
	prefix,
	question
}: MultiItemSubRow ) {
	return (
		<div className="faux-tr faux-tr--subrow">
			<div className="faux-td p-2 border-t border-t-rural-gray-300 pl-12 bg-rural-gray-150">
				<ElementLoader
					question={{
						...question,
						hideLabel: true,
						systemId: prefix ? `${ prefix }.${ question.systemId }` : question.systemId
					}}
					marginBottom={ false }
				/>
			</div>
		</div>
	);
}

function MultiItemRow({
	isFixedRows,
	removeRow,
	row,
	rowCount
}: MultiItemRow ) {
	const { isExporting } = useIsExporting();

	if ( !row ) {
		return null;
	}

	return (
		<div className="faux-tr">
			{ Object.entries( row.columns ).map( ( [ , questions ]: [ string, any ], index: number ) => (
				<div key={ index } className="faux-td text-left font-thin border-t border-t-rural-gray-500 p-2 first:pl-0 align-top">
					{ questions.map( ( question: any ) => (
						<ElementLoader
							key={ question.systemId }
							question={{
								...question,
								hideLabel: true
							}}
							marginBottom={ false }
						/>
					))}
				</div>
			))}

			{ !isExporting && (
				<div className="faux-td font-thin p-2 border-t border-t-rural-gray-500 first:pl-0 align-top text-right">
					{ !row.isRequiredRow && !isFixedRows && rowCount > 1 && (
						<button
							type="button"
							className="mt-2 text-rural-gray-500 text-lg font-bold"
							onClick={ () => removeRow( row.rowIndex ) }
						>
							&times;
						</button>
					)}
				</div>
			)}
		</div>
	);
}

export default function MultiItemTable({
	addMoreText,
	columnHeaders,
	getRowTemplate,
	isFixedRows,
	maximumRows,
	rows,
	subRowElements,
	systemId
}: MultiItemTable ) {
	const { unregister } = useFormContext();

	const [ tableRows, setTableRows ] = useState( rows );

	const { isExporting } = useIsExporting();

	useEffect( () => {
		setTableRows( rows );
	}, [ rows, setTableRows ] );

	const addRow = useCallback( () => {
		const filteredRows = tableRows.filter( a => a );
		const lastRowIndex = filteredRows[ filteredRows.length - 1 ].rowIndex;

		setTableRows( [ ...tableRows, getRowTemplate( lastRowIndex + 1 ) ] );
	}, [ getRowTemplate, setTableRows, tableRows ] );

	const removeRow = useCallback( ( rowIndex: number ) => {
		const tableRowCopy = [ ...tableRows ];

		const matchedRow = tableRowCopy.findIndex( row => row && row.rowIndex === rowIndex );

		if ( matchedRow >= 0 ) {
			tableRowCopy.splice( matchedRow, 1 );
		}

		setTableRows( tableRowCopy );

		unregister( `${ systemId }[${ rowIndex }]` );
	}, [ setTableRows, systemId, tableRows, unregister ] );

	const filteredRows = useMemo( () => {
		return tableRows.filter( a => a );
	}, [ tableRows ] );

	return (
		<div className="faux-table w-full" style={{ gridTemplateColumns: `repeat(${ isExporting ? columnHeaders.length : columnHeaders.length + 1 }, auto)`}}>
			<div className="faux-tr">
				{ columnHeaders.map( ( header: ColumnHeader ) => (
					<div key={ header.columnId } className="faux-th font-bold text-left p-2 text-sm border-b border-b-rural-gray-500 first:pl-0">{ header.columnLabel }</div>
				))}

				{ /* space for the delete button */ }
				{ !isExporting && (
					<div className="faux-th p-2 border-b border-b-rural-gray-500"></div>
				)}
			</div>

			{ filteredRows.map( ( row: QuestionRow ) => (
				<Fragment key={ `${ systemId }.${ row.rowIndex }` }>
					<MultiItemRow
						isFixedRows={ isFixedRows }
						removeRow={ removeRow }
						row={ row }
						rowCount={ filteredRows.length }
					/>

					{ subRowElements.map( ( question: any ) => (
						<MultiItemSubrow
							key={ `${ systemId }.${ row.rowIndex }` }
							question={ question }
							prefix={ `${ systemId }[${ row.rowIndex }]` }
						/>
					))}
				</Fragment>
			))}

			{ !isExporting && !isFixedRows && maximumRows >= filteredRows.length && (
				<div className="faux-tfoot">
					<div className="faux-tr border-none">
						<div className="faux-td">
							<button
								type="button"
								onClick={ addRow }
								className="text-sm font-bold py-2 text-rural-red-900"
							>
								{ addMoreText } +
							</button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}
