import { useEffect, useRef } from 'react';

import AdditionalQuestions from '@/components/AdditionalQuestions';

import { useIsExporting } from '@/hooks/useIsExporting';
import { accumulateValues } from '@/services/questions';

type ConditionalQuestionsProps = {
	options: any[];
	selectedValue: string | string[];
	unregister: Function;
};

function getAdditionalQuestions( selectedValue: string | string[], options: any[], ignoreConditional = false ) {
	let newAdditionalQuestions: any[];

	if ( ignoreConditional ) {
		newAdditionalQuestions = options.filter( o => o.additionalQuestions?.length > 0 ).flatMap( o => o.additionalQuestions );
	} else if ( Array.isArray( selectedValue ) ) {
		newAdditionalQuestions = options.filter( option => {
			return selectedValue.includes( option.value ) && option.additionalQuestions?.length > 0;
		}).flatMap( option => {
			return option.additionalQuestions;
		});
	} else {
		newAdditionalQuestions = options.find( option => {
			return option.value === selectedValue;
		})?.additionalQuestions || [];
	}

	return newAdditionalQuestions;
}

export function useConditionalQuestions({
	options,
	selectedValue,
	unregister
}: ConditionalQuestionsProps ) {
	const currentlySelected = useRef<any | undefined>();
	const { isBlankExport } = useIsExporting();

	useEffect( () => {
		if ( currentlySelected.current && selectedValue !== currentlySelected.current ) {
			const additionalQuestions = getAdditionalQuestions( currentlySelected.current, options, isBlankExport );

			let itemsToUnregister: any[] = [];

			accumulateValues( additionalQuestions, ( question: any ) => {
				if ( question.systemId ) {
					itemsToUnregister.push( question.systemId );
				}
			});

			itemsToUnregister.forEach( ( systemId: string ) => {
				unregister( systemId );
			});
		}

		currentlySelected.current = selectedValue;
	}, [ selectedValue ] );

	const newAdditionalQuestions = getAdditionalQuestions( selectedValue, options, isBlankExport );

	return {
		activeQuestions: newAdditionalQuestions,
		ConditionalQuestions: newAdditionalQuestions.length > 0 ? <AdditionalQuestions questions={ newAdditionalQuestions } /> : null
	};
}
