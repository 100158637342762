import type { PropsWithChildren } from 'react';

import { createContext, useCallback, useContext, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import { getForms } from '@/services/webservices/forms';

type FormSummary = {
	description?: string;
	id: string;
	title: string;
	validators: Validator[];
};

type FormsProviderProps = PropsWithChildren;

type FormsContextReturn = {
	formsData: FormSummary[];
	getForm: ( formGuid: string ) => FormSummary;
};

const FormsContext = createContext<FormsContextReturn | undefined>( undefined );

function FormsProvider({ children }: FormsProviderProps ) {
	const { data: formsData } = useQuery( [ 'forms' ], getForms );

	const getForm = useCallback( ( formGuid: string ) => {
		if ( !formsData ) {
			return {};
		}

		return formsData.find( ( form: any ) => form.id === formGuid );
	}, [ formsData ] );

	const returnValues = useMemo( () => ({
		formsData,
		getForm
	}), [ formsData, getForm ] );

	return (
		<FormsContext.Provider value={ returnValues }>
			{ children }
		</FormsContext.Provider>
	);
}

function useForms() {
	const context = useContext( FormsContext );

	if ( context === undefined ) {
		throw new Error( 'useForms must be used within a FormsProvider' );
	}

	return context;
}

export { FormsProvider, useForms };
