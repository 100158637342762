import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';

import MultiItemTable from './MultiItemTable';

function mapPredefinedRows( row: any, index: number, getRowTemplate: Function, systemId?: string ) {
	let rowTemplate = getRowTemplate( index, true );

	if ( row.predefinedCells ) {
		row.predefinedCells.forEach( ( cell: any ) => {
			if ( cell.overrideFields?.length > 0 ) {
				rowTemplate.columns[ cell.columnId ] = cell.overrideFields.map( ( override: any ) => {
					return {
						...override,
						systemId: systemId ? `${ systemId }[${ index }].${ override.systemId }` : override.systemId,
					};
				});
			} else {
				let updateObj = { ...rowTemplate.columns[ cell.columnId ][ 0 ] };
	
				// update the question's defaultValue property with the one in the cell's data
				// also if the cell's isReadOnly flag is set, change the fieldType and type values
				rowTemplate.columns[ cell.columnId ] = [{
					...updateObj,
					fieldType: cell.isReadOnly ? 'Text' : updateObj.fieldType,
					type: cell.isReadOnly ? 'readonly' : updateObj.type,
					defaultValue: cell.defaultValue
				}];
			}
		});
	}

	return rowTemplate;
}

function MultiItemData({
	addMoreText,
	columnHeaders,
	savedData,
	isFixedRows,
	maximumRows,
	predefinedRows,
	requiredRows,
	systemId,
	templateRow
}: MultiItemDataProps ) {
	const [ initialValues, setInitialValues ] = useState<QuestionRow[] | undefined>();

	const filteredHeaders = useMemo( () => {
		const filteredTemplateRows = templateRow.filter( ({ columnQuestions }: TemplateColumn ) => {
			return columnQuestions.filter( ( question: any ) => question.fieldType !== 'MultiItem' ).length > 0;
		});

		const matchedHeaders = columnHeaders.filter( ({ columnId }: ColumnHeader ) => {
			const matchedColumn = filteredTemplateRows.find( ( row: TemplateColumn ) => row.columnId === columnId );

			return matchedColumn ? true : false;
		});

		return matchedHeaders;
	}, [ columnHeaders, templateRow ] );

	const sortedTemplate = useMemo( () => {
		// make sure the rows are in the same order as the headers and
		// that rows only contain questions with valid headers.
		let row: any[] = [];

		filteredHeaders.forEach( ( columnHeader: ColumnHeader ) => {
			const matchedRow = templateRow.find( ( templateColumn: TemplateColumn ) => {
				return templateColumn.columnId === columnHeader.columnId;
			});

			if ( matchedRow ) {
				row.push( matchedRow );
			}
		});

		return row;
	}, [ filteredHeaders, templateRow ] );

	const subRowElements = useMemo( () => {
		let subRows: any[] = [];

		templateRow.forEach( ({ columnQuestions }: TemplateColumn ) => {
			subRows.push( columnQuestions.filter( ( question: any ) => question.fieldType === 'MultiItem' ) );
		});

		return subRows.flat();
	}, [ templateRow ] );

	const getRowTemplate = useCallback( ( rowIndex: number, isRequiredRow = false ) => {
		let returnObject: any = {};

		sortedTemplate.forEach( ({ columnId, columnQuestions }: TemplateColumn ) => {
			returnObject[ columnId ] = columnQuestions.map( ( question: any ) => {
				let returnObject = {
					...question,
					requiredPosition: 'field',
					systemId: `${ systemId }[${ rowIndex }].${ question.systemId }`
				};

				if ( question.validators?.length > 0 ) {
					const prefix = `${ systemId }[${ rowIndex }]`;

					returnObject.validators = question.validators.map( ( validator: Validator ) => {
						if ( ( validator as ValidationCompare ).comparisons ) {
							return {
								...validator,
								comparisons: ( validator as ValidationCompare ).comparisons.map( ( comparison: ValidationComparison ) => ({
									...comparison,
									systemId: `${ prefix }.${ comparison.systemId }`
								}))
							};
						}

						return validator;
					})
				}

				if ( question.fieldType === 'Select' ) {
					returnObject.addPrefix = true;
				}

				return returnObject;
			});
		});

		return { rowIndex, isRequiredRow, columns: returnObject };
	}, [ systemId, sortedTemplate ] );

	// get initial rows
	useEffect( () => {
		let requiredRowsArray: any[] = [];
		let initialValuesArray: any[] = [];

		// required rows are always displayed and will be listed first, so if we're loading for the first time,
		// load them here with their default values. If we're re-rendering, also load them here, but in that case
		// they'll have whatever value was entered.
		if ( requiredRows ) {
			requiredRowsArray = requiredRows.map( ( row: any, index: number ) => {
				return mapPredefinedRows( row, index, getRowTemplate, systemId );
			});
		}

		// if we have previously saved data, use it here.
		if ( savedData && savedData.length > 0 ) {
			// but first we need to see how many required rows we've already added,
			// because the stored data would include those
			const remainingRowCount = savedData.length - requiredRowsArray.length;
			const remainingSavedRows = remainingRowCount > 0 ? savedData.slice( remainingRowCount * -1 ) : savedData;
			
			initialValuesArray = remainingSavedRows.map( ( row: unknown, index: number ) => {
				if ( row ) {
					return getRowTemplate( requiredRowsArray.length + index );
				}
			});

			const combinedRows = [ ...requiredRowsArray, ...initialValuesArray ];

			// set the values and return because we don't want to include predefined rows on re-renders
			// as they'll already be accounted for in the saved data
			setInitialValues( combinedRows );

			return;
		}

		// if there's no saved data, it's most likely a re-render and we need to check for predefined rows
		if ( predefinedRows ) {
			initialValuesArray = predefinedRows.map( ( row: MultiItemPredefined, index: number ) => {
				return mapPredefinedRows( row, index, getRowTemplate, systemId );
			});
		}

		const combinedRows = [ ...requiredRowsArray, ...initialValuesArray ];

		if ( combinedRows.length < maximumRows ) {
			// add the template row
			combinedRows.push( getRowTemplate( combinedRows.length ) );
		}

		// set the value to all of the rows
		setInitialValues( combinedRows );
	}, [ getRowTemplate, maximumRows, predefinedRows, requiredRows, savedData, setInitialValues, systemId ] );

	// if initial values is undefined, it hasn't been checked and we want to delay render
	if ( initialValues === undefined ) {
		return null;
	}

	return (
		<MultiItemTable
			addMoreText={ addMoreText || 'Add' }
			columnHeaders={ filteredHeaders }
			getRowTemplate={ getRowTemplate }
			isFixedRows={ isFixedRows }
			maximumRows={ maximumRows }
			rows={ initialValues || [] }
			subRowElements={ subRowElements }
			systemId={ systemId }
		/>
	);
}

export default function MultiItem( props: MultiItem ) {
	return (
		<Controller
			name={ props.systemId }
			render={ ({ field }) => (
				<MultiItemData
					{ ...props }
					savedData={ field.value }
				/>
			)}
		/>
	);
}
