import { useMemo } from 'react';
import parse from 'html-react-parser';
import { Controller, useFormContext } from 'react-hook-form';

import { Error, Label } from '@/components/Form';

import { useConditionalQuestions } from '@/hooks/useConditionalQuestions';
import { useIsExporting } from '@/hooks/useIsExporting';

type RadioButtonBase = {
	additionalQuestions?: any;
	label: string;
	value: string;
};

type RadioButtonList = {
	defaultValue?: string;
	label?: string;
	orientation?: 'horizontal' | 'vertical';
	radioButtons: RadioButtonProps[];
	required?: boolean;
	systemId: string;
};

type RadioButtonInnerProps = RadioButtonList & {
	field: any;
};

type RadioButtonProps = RadioButtonBase & {
	checked: boolean;
	required: boolean;
	systemId: string;
};

function RadioButton({
	checked,
	label,
	required,
	systemId,
	value
}: RadioButtonProps ) {
	const { isBlankExport, isExporting } = useIsExporting();
	const { register } = useFormContext();

	return (
		<div>
			<label className="inline-flex items-center gap-2 text-sm">
				{ isExporting ? (
					<div className="rounded-full border-2 border-slate-700 w-5 h-5 p-0.5">
						{ ( !isBlankExport && checked ) && (
							<div className="block rounded-full w-full h-full bg-slate-600">{ '\u00a0' }</div>
						)}
					</div>
				) : (
					<input
						id={ `${ systemId }.${ value }` }
						type="radio"
						checked={ checked }
						value={ value }
						{ ...register( systemId, { required } ) }
					/>
				)}

				{ parse( label ) }
			</label>
		</div>
	);
}

function RadiobuttonListInner({
	field,
	label,
	orientation = 'horizontal',
	radioButtons = [],
	required = false,
	systemId
}: RadioButtonInnerProps ) {
	const { formState: { errors }, unregister } = useFormContext();

	const horizontalClassName = 'flex gap-x-4';

	const { activeQuestions, ConditionalQuestions } = useConditionalQuestions( useMemo( () => ({
		options: radioButtons,
		selectedValue: field.value,
		unregister
	}), [ radioButtons, field.value, unregister ] ));

	return (
		<div className="keep-it-together">
			<Label
				errors={ <Error id={ systemId } errors={ errors } /> }
				isRequired={ required }
			>
				{ label }
			</Label>

			<div className={ orientation === 'horizontal' ? horizontalClassName : '' }>
				{ radioButtons.map( ( radiobutton: RadioButtonProps ) => (
					<RadioButton
						key={ `${ systemId }.${ radiobutton.value }` }
						systemId={ systemId }
						label={ radiobutton.label }
						value={ radiobutton.value }
						checked={ radiobutton.value === field.value }
						required={ required }
					/>
				))}
			</div>

			{ activeQuestions.length > 0 && (
				<div className="my-2">
					{ ConditionalQuestions }
				</div>
			)}
		</div>
	);
}

export default function RadiobuttonList( props: RadioButtonList ) {
	const { control } = useFormContext();

	return (
		<Controller
			name={ props.systemId }
			control={ control }
			rules={{ required: props.required }}
			defaultValue={ props.defaultValue }
			render={ ({ field }) => (
				<RadiobuttonListInner
					field={ field }
					{ ...props }
				/>
			)}
		/>
	);
}
