import { apiCall } from '@/services/webservices/shared';

type SortObject = {
	id: string;
	desc: boolean;
};

type FilterTypes = 'agent' | 'underwriter' | 'coverage' | 'programcode';
export type ActiveFilters = Partial<Record<FilterTypes, SelectOption[]>>;

export async function getDashboard( start: number, take: number, filter?: ActiveFilters, searchStr?: string, sorting: SortObject[] = [] ) {
	let sortColumn = 'lastModified';
	let sortDesc = true;
	let agents: string[] = [];
	let underwriters: string[] = [];
	// let coverages = [];
	// let programcodes = [];

	if ( sorting[ 0 ] ) {
		sortColumn = sorting[ 0 ].id;
		sortDesc = sorting[ 0 ].desc;
	}

	if ( filter && Object.keys( filter ).length > 0 ) {
		if ( filter.agent ) {
			agents = filter.agent.map( agent => agent.value );
		}

		if ( filter.underwriter ) {
			underwriters = filter.underwriter.map( underwriter => underwriter.value );
		}

		//if (filter?.coverage) {
		//	console.log('coverage data', filter.coverage);
		//	let i = 0;
		//	while (i < filter?.coverage.length) {
		//		coverages.push(filter?.coverage[i].value);
		//		i++;
		//	}	
		//}
		//if (filter?.programcode) {
		//	console.log('programcode data', filter.programcode);
		//	let i = 0;
		//	while (i < filter?.programcode.length) {
		//		programcodes.push(filter?.programcode[i].value);
		//		i++;
		//	}	
		//}	
	}

	const sortingMap: Record<string, string> = {
		id: 'ApplicationId',
		clientName: 'ClientName',
		clientGuid: 'ClientNumber',
		quoteNumber: 'QuoteNumber',
		lastModified: 'LastModified',
		quoteEffectiveDate: 'EffectiveDate',
		agent: 'Agent'
	};

	return apiCall( '/Dashboard', {
		method: 'POST',
		body: JSON.stringify({
			start,
			take,
			search: searchStr,
			sortColumn: sortingMap[ sortColumn ],
			sortDirection: sortDesc ? 1 : 0,
			agents: agents,
			coverages: [],
			programCodes: [],
			underwriters: underwriters
		}),
		headers: {
			'Content-Type': 'application/json'
		}
	}, 'There was an erorr loading the dashboard' );
}

export async function getCpp( cppId: string ): Promise<CppDetailsResponse> {
	return apiCall( `/CppApplication/cpp?cppApplicationId=${ cppId }`, {}, 'There was an error fetching the CPP details' );
}

export async function createCpp( requestBody: any, isMidterm = false, quoteEffectiveDate: string ) {
	return apiCall(`/CppApplication`, {
		method: 'POST',
		body: JSON.stringify({
			agentId: requestBody.agentId,
			isMidterm,
			quoteEffectiveDate,
			jsonDocument: {}
		}),
		headers: {
			'Content-Type': 'application/json'
		}
	}, 'There was an error creating a new CPP' );
}

export async function saveCpp( cppId: string, jsonDocument: any, agentId?: string ) {
	return apiCall( `/CppApplication`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			cppApplicationId: cppId,
			agentId: agentId,
			jsonDocument: jsonDocument
		})
	}, 'There was an error saving the form details' );
}

export async function updateGeneralInformation( cppId: string, generalInformation?: any ) {
	return apiCall( `/CppApplication/general-information`, {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			cppApplicationId: cppId,
			...generalInformation
		})
	}, 'There was an error saving the general information' );
}

export async function updateUnderwriter( cppId: string, underwriterId: number ) {
	return apiCall( `/Underwriter?cppApplicationId=${ cppId }&underwriterId=${underwriterId}`, {
		method: 'PUT'
	}, 'There was an error copying this form', false );
}

export async function deleteCpp( cppId: string ) {
	return apiCall( `/CppApplication?id=${ cppId }`, {
		method: 'DELETE',
	}, 'There was an error deleting this form', false );
}

export async function copyCpp( cppId: string, cppEffectiveDate: Date ) {
	return apiCall( `/CppApplication/copy/cpp`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			cppApplicationId: cppId,
			quoteEffectiveDate: cppEffectiveDate
		})
	}, 'There was an error copying this form', false );
}

export async function activateOrInactivateCpp( cppId: string, activationStatus: boolean ) {
	return apiCall( `/CppApplication/cpp?cppApplicationId=${ cppId }&bInactivateCpp=${ activationStatus }`, {
		method: 'POST'
	}, 'There was an error changing the activation status of this CPP', false );
}

export async function createApplicationHistory(cppId: string, formId: string, action: string){
	return apiCall(`/ApplicationHistory?cppApplicationId=${ cppId }&formId=${ formId }&action=${ action }`, {
		method: 'POST'
	}, 'There was an error creating the history log for this CPP', false );
}

export async function getAgents() {
	return apiCall(`/Agent/agents`, {
		method: 'GET'
	}, 'There was an error fetching agent details');
}

export async function getUnderwriters() {
	return apiCall(`/Underwriter`, {
		method: 'GET'
	}, 'There was an error fetching underwriter details');
}

export async function getHistory(cppId: string, start: number, take: number ): Promise<HistoryResponse> {
	return apiCall(`/ApplicationHistory?cppApplicationId=${cppId}&start=${start}&take=${take }`);
}

export async function updateToDos( finishedToDos : any ) {
	const todoResponse = await apiCall(`/CppApplicationToDo`,
	{
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			toDoListIds: finishedToDos.toDoListIds,
			isDone: finishedToDos.isDone
		})
	}, 'There was an error updating todo items');
}

export async function zipCppApplications( cppId: string, pdfs: {pdf: Blob, fileName: string }[]) {
	const formData = new FormData();
	pdfs.forEach(pdf => formData.append('file', pdf.pdf, pdf.fileName));
	const response = await apiCall(`/CppApplication/cpp/zip?cppApplicationId=${cppId}`, {
		method: 'POST',
		body: formData
	}, 'There was an error zipping application pdfs', false);

	const blob = await response.blob();
	const link = document.createElement('a');
	link.href = window.URL.createObjectURL(blob);
	link.setAttribute('download', `CPP-${cppId}_Full-Export.zip`);
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
}
