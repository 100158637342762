import type { FieldValues } from 'react-hook-form';

import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import AgentSelect from '@/components/AgentSelect';
import Button from '@/components/Button';
import Header from '@/components/Header';
import { Date } from '@/components/FormElements';

type CreateCppModalProps = {
	isMidterm: boolean;
	onClose: () => void;
	onConfirm: ( agentId: { agentId: string }, effectiveDate: string, isMidterm: boolean ) => void;
};

export default function CreateCppModal({
	isMidterm = false,
	onClose,
	onConfirm
}: CreateCppModalProps ) {
	const { handleSubmit, reset } = useFormContext();

	const onCancel = useCallback( () => {
		reset();
		onClose();
	}, [ onClose, reset ] );

	const submitForm = useCallback( ( data: FieldValues ) => {
		const agentData = { agentId: data?.associatedAgents?.value };

		onConfirm( agentData, data.cppEffectiveDate, isMidterm );

		onCancel();
	}, [ isMidterm, onCancel, onConfirm ] );

	return (
		<div>
			<Header element="h2" variant="h3">Create CPP</Header>

			<form onSubmit={ handleSubmit( submitForm ) }>
				<AgentSelect />

				<Date
					className="mt-4"
					label="CPP Effective Date"
					systemId="cppEffectiveDate"
				/>

				<div className="flex gap-x-4 mt-8">
					<Button type="submit" variant="primary" size="large">Create CPP</Button>
					<Button type="button" variant="border" size="large" onClick={ () => onCancel() }>Cancel</Button>
				</div>
			</form>
		</div>
	);
}
