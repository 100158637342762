import type { ReactNode } from 'react';

type KeyProps = {
	children: ReactNode;
};

type ValueProps = {
	children: ReactNode;
};

export function Key({
	children
}: KeyProps ) {
	return (
		<div className="text-sm font-thin mb-1">
			{ children }
		</div>
	);
}

export function Value({
	children
}: ValueProps ) {
	return (
		<div className="text-md font-bold">
			{ children }
		</div>
	);
}
