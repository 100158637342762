import { Fragment } from 'react';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';

type TableVariants = 'comfy' | 'compressed' | 'mini';

export type TableProps = {
	data: any;
	columns: any[];
	rowSelection?: any;
	setRowSelection?: any;
	sorting?: any[];
	setSorting?: any;
	subRow?: any;
	variant?: TableVariants;
};

type TableObject = {
	header: string;
	footer: string;
	body: string;
};

const variantMap: Record<TableVariants, TableObject> = {
	comfy: {
		header: 'p-2 pb-3',
		footer: 'p-2',
		body: 'p-2 py-4 text-md'
	},
	compressed: {
		header: 'p-2 text-xs',
		footer: 'p-2',
		body: 'p-2 py-3 text-sm',
	},
	mini: {
		header: 'p-1 pb-2 text-xs',
		footer: '',
		body: 'p-1 text-sm'
	}
};

export default function Table({
	data,
	columns,
	subRow,
	variant = 'comfy',
	rowSelection = {},
	setRowSelection = () => {},
	sorting = [],
	setSorting = () => {}
}: TableProps ) {
	const table = useReactTable({
		data,
		columns,
		getCoreRowModel: getCoreRowModel(),
		manualSorting: true,
		manualExpanding: true,
		state: {
			rowSelection,
			sorting
		},
		onRowSelectionChange: setRowSelection,
		onSortingChange: setSorting
	});

	const variantClass = variantMap[ variant ] || { header: '', footer: '', body: '' };

	return (
		<div>
			<table className="w-full mb-4 border-b border-b-rural-gray-700">
				<thead>
					{ table.getHeaderGroups().map( headerGroup => (
						<tr key={ headerGroup.id }>
							{ headerGroup.headers.map( header => (
								<th key={ header.id } className={ `${ variantClass.header } font-bold text-left text-sm border-b border-b-rural-gray-700 first:pl-0 cursor-pointer` }>
									<div className="flex gap-x-2" onClick={ header.column.getToggleSortingHandler() }>
										{ header.isPlaceholder ? null : flexRender( header.column.columnDef.header, header.getContext() ) }

										<span>
											{{
												asc: '▲',
												desc: '▼'
											}[ header.column.getIsSorted() as string ] ?? null }
										</span>
									</div>
								</th>
							))}
						</tr>
					))}
				</thead>
				
				{/* <tfoot>
					{ table.getFooterGroups().map( footerGroup => (
						<tr key={ footerGroup.id }>
							{ footerGroup.headers.map( header => (
								<th key={ header.id } className={ `${ variantClass.footer } text-left first:pl-0` }>
									{ header.isPlaceholder ? null : flexRender( header.column.columnDef.footer, header.getContext() ) }
								</th>
							))}
						</tr>
					))}
				</tfoot> */}

				{ table.getRowModel().rows.length === 0 ? (
					<tbody>
						<tr>
							<td className="p-2 text-sm font-thin" colSpan={ table.getFlatHeaders().length }>No records found.</td>
						</tr>
					</tbody>
				) : (
					<tbody>
						{ table.getRowModel().rows.map( row => (
							<Fragment key={ row.id }>
								<tr className={ ( row.original as any ).className || '' }>
									{ row.getVisibleCells().map( cell => (
										<td key={ cell.id } className={ `${ variantClass.body } text-left font-thin border-t border-t-rural-gray-700 first:pl-0` }>
											{ flexRender( cell.column.columnDef.cell, cell.getContext() ) }
										</td>
									))}
								</tr>

								{ row.getIsExpanded() && subRow && (
									<tr>
										<td colSpan={ table.getFlatHeaders().length }>
											{ subRow({ row }) }
										</td>
									</tr>
								)}
							</Fragment>
						))}
					</tbody>
				)}
			</table>
		</div>
	);
}
