import type { ReactNode } from 'react';

type HeaderElement = {
	children: ReactNode;
	className: string;
};

type Elements = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

type HeaderProps = {
	children: ReactNode;
	className?: string;
	element: Elements;
	spacing?: string;
	variant?: Elements;
};

const ElementMap: Record<Elements, any> = {
	'h1': H1,
	'h2': H2,
	'h3': H3,
	'h4': H4,
	'h5': H5,
	'h6': H6
};

const variantMap: Record<Elements, string> = {
	'h1': 'text-3xl font-bold',
	'h2': 'text-xl font-bold',
	'h3': 'text-lg font-bold',
	'h4': 'text-md font-bold',
	'h5': 'text-md',
	'h6': 'text-sm'
};

const spacingMap: Record<Elements, string> = {
	'h1': 'mb-3',
	'h2': 'mb-2',
	'h3': 'mb-2',
	'h4': 'mb-2',
	'h5': 'mb-2',
	'h6': 'mb-2'
};

function H1({ children, className }: HeaderElement ) {
	return <h1 className={ className }>{ children }</h1>;
}

function H2({ children, className }: HeaderElement ) {
	return <h2 className={ className }>{ children }</h2>;
}

function H3({ children, className }: HeaderElement ) {
	return <h3 className={ className }>{ children }</h3>;
}

function H4({ children, className }: HeaderElement ) {
	return <h4 className={ className }>{ children }</h4>;
}

function H5({ children, className }: HeaderElement ) {
	return <h5 className={ className }>{ children }</h5>;
}

function H6({ children, className }: HeaderElement ) {
	return <h6 className={ className }>{ children }</h6>;
}

export default function Header({
	children,
	className,
	element = 'h2',
	spacing,
	variant = element
}: HeaderProps ) {
	const HeaderElement = ElementMap[ element ];
	const headerClass = variantMap[ variant ] || '';
	const spacingClass = spacing || spacingMap[ variant ];

	return (
		<HeaderElement className={ `${ headerClass } ${ className } ${ spacingClass }` }>
			{ children }
		</HeaderElement>
	);
}
