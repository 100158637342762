import Table from '@/components/Table';

import { locationColumns } from '@/definitions/locationsTable';

type AddLocationProps = {
	locations?: any[];
};

function NoLocations() {
	return (
		<div className="mt-2 mb-8 p-5 border border-dashed border-rural-gray-500 bg-rural-gray-150">
			<span className="text-lg">
				There are no buildings/risks yet. { ' ' }
				<button className="font-bold text-rural-red-900">Add one now.</button>
			</span>
		</div>
	);
}

export default function AddLocation({
	locations = []
}: AddLocationProps ) {
	return (
		<>
			{ locations.length === 0 && <NoLocations /> }

			{ locations.length > 0 && (
				<Table
					data={ locations }
					columns={ locationColumns }
					variant="compressed"
				/>
			)}
		</>
	);
}
