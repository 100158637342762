import AdditionalQuestions from '@/components/AdditionalQuestions';
import Grid, { Column } from '@/components/Grid';

type TableProps = {
	identifier: string;
	label: string;
	questions: any[];
	shouldHideNoResponses?: boolean;
};

export default function Table({
	identifier,
	label,
	questions,
	shouldHideNoResponses
}: TableProps ) {
	return (
		<Grid className="border-b border-rural-gray-500 text-sm mx-2 py-4 questionTable" align="top">
			<Column columns={ 1 } className="questionTable-identifier">
				{ identifier && <span>{ identifier }</span> }
			</Column>

			<Column columns={ 9 } className="questionTable-main">
				{ label && <div className="mb-4">{ label }</div> }

				{ questions.length > 0 && (
					<AdditionalQuestions
						questions={ questions }
						shouldHideNoResponses={ shouldHideNoResponses }
					/>
				)}
			</Column>

			<Column columns={ 2 } />
		</Grid>
	);
}
