import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

export default function ReadOnly({
	defaultValue,
	systemId
}: any ) {
	const [ valueString, setValueString ] = useState( '' );
	const { getValues, register, setValue } = useFormContext();

	useEffect( () => {
		const value = getValues( systemId );

		if ( !value && defaultValue ) {
			setValue( systemId, defaultValue );

			setValueString( defaultValue );
		}

		if ( typeof value === 'string' ) {
			setValueString( value );
		} else {
			setValueString( value?.value || '' );
		}
	}, [ defaultValue, getValues, setValue, systemId ] );

	return (
		<>
			{ valueString }

			<input
				{ ...register( systemId ) }
				type="hidden"
			/>
		</>
	);
}
