import AdditionalQuestions from '@/components/AdditionalQuestions';
import Header from '@/components/Header';
import ExternalLink from '@/components/ExternalLink';

import { useIsExporting } from '@/hooks/useIsExporting';

type SectionProps = {
	title: string;
	titleLinkText?: string;
	titleLinkHref?: string;
	description: string;
	questions?: any[];
	hideNoResponses?: boolean;
	hideTextOnExport?: boolean;
	hideOnExport?: boolean;
};

export default function Section({
	title,
	titleLinkText,
	titleLinkHref,
	description,
	questions = [],
	hideNoResponses,
	hideTextOnExport = false,
	hideOnExport = false
}: SectionProps ) {
	const { isBlankExport, isExporting } = useIsExporting();

	if ( isExporting && hideOnExport ) {
		return null;
	}

	return (
		<div id={ title?.split( ' ' ).join( '-' ).toLowerCase() || '' } className="pb-4">
			{ !isBlankExport && hideTextOnExport && isExporting ? (
				<></>
			) : (
				<div className="mb-6">
					<Header element="h3">
						{ title }
						
						<ExternalLink href={ titleLinkHref }>{ titleLinkText }</ExternalLink>
					</Header>

					{ description && <p className="section-description text-sm mb-2">{ description }</p> }
				</div>
			)}

			<AdditionalQuestions
				questions={ questions }
				shouldHideNoResponses={ hideNoResponses }
			/>
		</div>
	);
}
