import { useMemo } from 'react';
import { IMaskInput } from 'react-imask';
import { Controller, useFormContext } from 'react-hook-form';

import { Error, Label, RequiredIndicator } from '@/components/Form';
import { CharRemaining } from '@/components/FormElements';

import { useIsExporting } from '@/hooks/useIsExporting';
import { isBetween, isGreaterThan, isLessThan, isNumeric, validatePhone, maxLength } from '@/services/validation';

type TextType = 'text' | 'email' | 'tel' | 'number' | 'currency' | 'multi' | 'hidden' | 'readonly' | 'percentage';

type TextProps = {
	defaultValue?: string;
	disabled?: boolean;
	hideLabel?: boolean;
	label: string;
	placeholder?: string;
	required?: boolean;
	size?: 'mini' | 'medium';
	systemId: string;
	type: TextType;
	validate?: any;
	width?: 'small' | 'medium' | 'large' | 'full';
	maxLen?: number;
	minimumValue?: number;
	maximumValue?: number;
	formatMask?: string;
	requiredPosition?: RequiredPosition;
};

type TextInputProps = {
	baseClass: string;
	defaultValue?: string;
	disabled?: boolean;
	placeholderValue?: string;
	required?: boolean;
	systemId: string;
	type: TextType;
	validate?: any;
};

type TextareaInputProps = {
	className: string;
	defaultValue?: string;
	disabled?: boolean;
	placeholder?: string;
	required?: boolean;
	systemId: string;
	validate?: any;
};

type NumericInputProps = {
	className: string;
	defaultValue?: string;
	disabled?: boolean;
	placeholder?: string;
	required: any;
	systemId: string;
	maximumValue?: number;
	minimumValue?: number;
};

type MaskedInputProps = {
	systemId: string;
	placeholder?: string;
	className: string;
	defaultValue?: string;
	disabled?: boolean;
	required?: boolean;
	validate?: any;
	formatMask: string;
};

type PercentageInputProps = {
	className: string;
	disabled?: boolean;
	placeholder?: string;
	required?: boolean;
	systemId: string;
};

function ReadonlyInput({
	defaultValue,
	systemId
}: any ) {
	const { getValues, register } = useFormContext();

	const text = useMemo( () => {
		if ( !systemId ) {
			return '';
		}

		const value = getValues( systemId );

		if ( value && typeof value === 'object' ) {
			return value.label;
		}

		return value || defaultValue;
	}, [ defaultValue, getValues, systemId ] );

	return (
		<div className="">
			<span className="font-thin">{ text }</span>

			<input
				id={ systemId }
				type="hidden"
				readOnly={ true }
				defaultValue={ defaultValue }
				{ ...register( systemId ) }
			/>
		</div>
	);
}

function MaskedInput({
	systemId,
	placeholder,
	className,
	defaultValue,
	disabled,
	required,
	validate,
	formatMask
}: MaskedInputProps ) {
	const { control } = useFormContext();

	return (
		<Controller
			name={ systemId }
			control={ control }
			rules={{ required, validate }}
			defaultValue={ defaultValue }
			render={ ({ field }) => {
				return (
					<IMaskInput
						mask={[
							{ mask: '', overwrite: true },
							{ mask: formatMask, overwrite: true }
						]}
						lazy={ true }
						placeholderChar="#"
						unmask={ true }
						// @ts-ignore
						id={ systemId }
						className={ className }
						disabled={ disabled }
						placeholder={ placeholder }
						autoComplete="new-password"
						value={ field.value }
						onAccept={ field.onChange }
					/>
				)
			}}
		/>
	);
}

function PercentageInput({
	className,
	disabled,
	placeholder,
	required,
	systemId
}: PercentageInputProps ) {
	const { control } = useFormContext();

	return (
		<Controller
			name={ systemId }
			control={ control }
			rules={{ required }}
			render={ ({ field }) => {
				return (
					<IMaskInput
						mask={[
							{
								mask: 'num%',
								lazy: false,
								blocks: {
									num: {
										lazy: false,
										mask: Number,
										overwrite: true,
										padFractionalZeros: false,
										radix: '.',
										thousandsSeparator: ','
									}
								}
							}
						]}
						unmask={ true }
						// @ts-ignore
						id={ systemId }
						className={ className }
						disabled={ disabled }
						placeholder={ placeholder }
						autoComplete="new-password"
						value={ field.value }
						onAccept={ field.onChange }
					/>
				)
			}}
		/>
	)
}

function PhoneInput({
	className,
	disabled,
	placeholder,
	required,
	systemId
}: NumericInputProps ) {
	const { control } = useFormContext();

	return (
		<Controller
			name={ systemId }
			control={ control }
			rules={{
				required,
				validate: {
					phone: v => validatePhone( v, required )
				}
			}}
			render={ ({ field }) => {
				return (
					<IMaskInput
						// @ts-ignore
						id={ systemId }
						className={ className }
						disabled={ disabled }
						placeholder={ placeholder }
						autoComplete='new-password'
						mask={[
							{ mask: '' },
							{ mask: '(000) 000-0000' }
						]}
						unmask={ true }
						value={ field.value }
						onAccept={ field.onChange }
					/>
				);
			}}
		/>
	);
}

function NumericInput({
	className,
	defaultValue,
	disabled,
	minimumValue,
	maximumValue,
	placeholder,
	required,
	systemId
}: NumericInputProps ) {
	const { isBlankExport, isExporting } = useIsExporting();
	const { control } = useFormContext();

	let validate: Record<string, any> = {
		numeric: ( v: string ) => isNumeric( v, required )
	};

	if ( minimumValue && maximumValue ) {
		validate[ 'between' ] = ( v: string ) => isBetween( v, minimumValue, maximumValue, required );
	} else {
		if ( minimumValue ) {
			validate[ 'minimumValue' ] = ( v: string ) => isGreaterThan( v, minimumValue, required );
		}
	
		if ( maximumValue ) {
			validate[ 'maximumValue' ] = ( v: string ) => isLessThan( v, maximumValue, required );
		}
	}

	return (
		<Controller
			name={ systemId }
			control={ control }
			rules={{ required, validate }}
			defaultValue={ defaultValue }
			render={ ({ field }) => {
				if ( !isBlankExport && isExporting ) {
					return (
						<div className={ `${ className } pr-5 overflow-word` }>
							{ field.value ? (
								<div className="pr-6">
									{ `${ Number( field.value ).toLocaleString( 'en-US' ) }` }
								</div>
							) : (
								<>{ '\u00A0' }</>
							)}
						</div>
					);
				}

				if ( isBlankExport && isExporting ) {
					return (
						<div className={ `${ className } pr-5 overflow-word` }>
							<>{ '\u00A0' }</>
						</div>
					);
				}

				return (
					<IMaskInput
						mask={[
							{
								mask: Number,
								scale: 5,
								lazy: false,
								thousandsSeparator: ',',
								radix: '.',
								overwrite: true
							}
						]}
						unmask={ true }
						value={ field.value }
						onAccept={ field.onChange }
						// @ts-ignore
						id={ systemId }
						className={ className }
						disabled={ disabled }
						placeholder={ placeholder }
						autoComplete="new-password"
					/>
				);
			}}
		/>
	);
}

function CurrencyInput({
	className,
	disabled,
	placeholder,
	required,
	systemId,
	maximumValue,
	minimumValue
}: NumericInputProps ) {
	const { isBlankExport, isExporting } = useIsExporting();
	const { control } = useFormContext();

	let validate: Record<string, any> = {
		numeric: ( v: string ) => isNumeric( v, required )
	};

	if ( minimumValue && maximumValue ) {
		validate[ 'between' ] = ( v: string ) => isBetween( v, minimumValue, maximumValue, required );
	} else {
		if ( minimumValue ) {
			validate[ 'minimumValue' ] = ( v: string ) => isGreaterThan( v, minimumValue, required );
		}
	
		if ( maximumValue ) {
			validate[ 'maximumValue' ] = ( v: string ) => isLessThan( v, maximumValue, required );
		}
	}

	return (
		<Controller
			name={ systemId }
			control={ control }
			rules={{
				required,
				validate
			}}
			render={ ({ field }) => {
				if ( !isBlankExport && isExporting ) {
					return (
						<div className={ `${ className } pr-5 overflow-word` }>
							{ field.value ? (
								<div className="pr-6">
									{ `$${ Number( field.value ).toLocaleString( 'en-US' ) }` }
								</div>
							) : (
								<>{ '\u00A0' }</>
							)}
						</div>
					);
				}

				return (
					<IMaskInput
						mask={[
							{
								mask: '$',
								overwrite: false,
								lazy: false
							},
							{
								mask: '$num',
								lazy: false,
								blocks: {
									num: {
										lazy: false,
										mask: Number,
										overwrite: true,
										padFractionalZeros: false,
										radix: '.',
										scale: 2,
										thousandsSeparator: ','
									}
								}
							}
						]}
						unmask={ true }
						// @ts-ignore
						id={ systemId }
						className={ className }
						disabled={ disabled }
						placeholder={ placeholder }
						autoComplete="new-password"
						value={ typeof field.value === 'string' ? field.value : '' }
						onAccept={ field.onChange }
					/>
				);
			}}
		/>
	);
}

function TextareaInput({
	className,
	defaultValue,
	disabled,
	placeholder,
	required,
	systemId,
	validate
}: TextareaInputProps ) {
	const { isExporting } = useIsExporting();
	const { control } = useFormContext();

	return (
		<Controller
			name={ systemId }
			control={ control }
			rules={{ required, validate }}
			defaultValue={ defaultValue }
			render={ ({ field }) => {
				if ( isExporting ) {
					return (
						<div className={ `${ className } min-h-[75px]` }>
							{ field.value || '\u00A0' }
						</div>
					);
				}

				return (
					<textarea
						id={ systemId }
						placeholder={ placeholder }
						className={ className }
						rows={ 5 }
						cols={ 20 }
						disabled={ disabled }
						onChange={ field.onChange }
						value={ field.value || '' }
					/>
				);
			}}
		/>
	)
}

function TextInput({
	baseClass,
	defaultValue,
	disabled,
	placeholderValue,
	required,
	systemId,
	type,
	validate
}: TextInputProps ) {
	const { isBlankExport, isExporting } = useIsExporting();
	const { control } = useFormContext();

	return (
		<Controller
			name={ systemId }
			control={ control }
			rules={{ required, validate }}
			defaultValue={ defaultValue }
			render={ ({ field }) => {
				if ( !isBlankExport && isExporting ) {
					return (
						<div className={ `${ baseClass } min-w-[5rem] pr-5` }>
							<div className="pr-2">
								{ field.value || '\u00A0' }
							</div>
						</div>
					);
				}

				return (
					<input
						id={ systemId }
						type={ type }
						placeholder={ placeholderValue }
						className={ baseClass }
						disabled={ disabled }
						value={ isBlankExport ? '' : field.value || '' }
						onChange={ field.onChange }
						autoComplete="new-password"
					/>
				);
			}}
		/>
	)
}

export default function Text({
	defaultValue,
	disabled,
	hideLabel = false,
	label,
	placeholder,
	required,
	size = 'medium',
	systemId,
	type,
	width = 'small',
	validate = {},
	maxLen = 0,
	minimumValue,
	maximumValue,
	formatMask,
	requiredPosition = 'label'
}: TextProps ) {
	const { formState: { errors } } = useFormContext();

	const sizeClassMap: Record<string, string> = {
		'mini': 'p-1',
		'medium': 'p-2'
	};

	const typeClassMap: Record<string, string> = {
		'text': '',
		'email': '',
		'tel': '',
		'currency': '',
		'multi': '',
		'hidden': '',
		'readonly': ''
	};

	const widthClassMap: Record<string, string> = {
		'small': 'max-w-xs',
		'medium': 'max-w-md',
		'large': 'max-w-lg',
		'full': 'max-w-full'
	};

	const baseClass = `border border-rural-gray-500 w-full placeholder:text-rural-gray-400 ${ sizeClassMap[ size ] } ${ disabled ? 'bg-gray-200' : 'bg-white' }`;

	const placeholderValue = useMemo( () => {
		let returnVal = placeholder === null ? '' : placeholder;

		switch ( type ) {
			case 'email': {
				returnVal = returnVal || 'name@email.com';
				break;
			}

			case 'tel': {
				returnVal = returnVal || '(###) ###-####';
				break;
			}
		}

		return returnVal;
	}, [ type, placeholder ] );

	if ( maxLen > 0 ) {
		validate[ 'maxLength' ] = ( v: string ) => maxLength( v, maxLen );
	}

	return (
		<div className="keep-it-together">
			<Label
				errors={ <Error id={ systemId } errors={ errors } /> }
				htmlFor={ systemId }
				isRequired={ required }
				isHidden={ hideLabel }
				hideRequired={ type === 'readonly' || requiredPosition === 'field' }
			>
				{ label }
			</Label>

			<div className={ `flex gap-x-1 ${ widthClassMap[ width ] } ${ typeClassMap[ type ] }` }>
				{ type === 'multi' && (
					<TextareaInput
						className={ baseClass }
						disabled={ disabled }
						placeholder={ placeholderValue }
						required={ required }
						systemId={ systemId }
						validate={ validate }
						defaultValue={ defaultValue }
					/>
				)}

				{ type === 'tel' && (
					<PhoneInput
						className={ baseClass }
						placeholder={ placeholderValue }
						systemId={ systemId }
						required={ required }
						disabled={ disabled }
					/>
				)}

				{ type === 'currency' && (
					<CurrencyInput
						className={ baseClass }
						defaultValue={ defaultValue }
						placeholder={ placeholderValue }
						systemId={ systemId }
						required={ required }
						disabled={ disabled }
						minimumValue={ minimumValue }
						maximumValue={ maximumValue }
					/>
				)}

				{ type === 'number' && (
					<NumericInput
						className={ baseClass }
						defaultValue={ defaultValue }
						placeholder={ placeholderValue }
						systemId={ systemId }
						required={ required }
						disabled={ disabled }
						minimumValue={ minimumValue }
						maximumValue={ maximumValue }
					/>
				)}

				{ type === 'readonly' && (
					<ReadonlyInput
						defaultValue={ defaultValue }
						systemId={ systemId }
					/>
				)}

				{ type === 'percentage' && (
					<PercentageInput
						className={ baseClass }
						disabled={ disabled }
						placeholder={ placeholderValue }
						systemId={ systemId }
						required={ required }
					/>
				)}

				{ ( formatMask ) && (
					<MaskedInput
						systemId={ systemId }
						placeholder={ placeholderValue }
						className={ baseClass }
						defaultValue={ defaultValue }
						disabled={ disabled }
						required={ required }
						validate={ validate }
						formatMask={ formatMask }
					/>
				)}

				{ ( !formatMask && type === 'text' || type === 'email' || type === 'hidden' ) && (
					<TextInput
						baseClass={ baseClass }
						defaultValue={ defaultValue }
						disabled={ disabled }
						placeholderValue={ placeholderValue }
						required={ required }
						systemId={ systemId }
						type={ type }
						validate={ validate }
					/>
				)}

				{ ( required && requiredPosition === 'field' ) && (
					<RequiredIndicator />
				)}
			</div>

			<CharRemaining
				charLimit={ maxLen }
				systemId={ systemId }
			/>
		</div>
	);
}
