import { useEffect, useMemo } from 'react';
import ReactSelect from 'react-select';
import { Controller, useFormContext } from 'react-hook-form';

import { Error, Label, RequiredIndicator } from '@/components/Form';

import { useConditionalQuestions } from '@/hooks/useConditionalQuestions';
import { useCpp } from '@/hooks/useCpp';
import { useIsExporting } from '@/hooks/useIsExporting';
import { useTheme } from '@/hooks/useTheme';
import { compareDates, getDateFromSquidex } from '@/services/dates';

type SelectSizes = 'default' | 'mini';

type BaseSelectProps = {
	id: string;
	defaultValue?: string;
	placeholder?: string;
	options: any;
	onChange: any;
	size?: SelectSizes;
};

type SharedSelectProps = {
	addPrefix?: boolean;
	defaultValue?: string;
	hideLabel?: boolean;
	label: string;
	placeholder?: string;
	required?: boolean;
	requiredPosition?: RequiredPosition;
	size?: SelectSizes;
	systemId: string;
};

type SelectOuterProps = SharedSelectProps & {
	isInCpp?: boolean;
	options: SelectOption[];
};

type SelectInnerProps = {
	systemId: string;
	field: any;
	hideLabel: boolean;
	justOptions: SelectOption[];
	label: string;
	mappedOptions: SelectOption[];
	placeholder: string;
	required: boolean;
	size: SelectSizes;
	requiredPosition?: RequiredPosition;
};

type SelectControllerProps = SharedSelectProps & {
	mappedOptions: SelectOption[];
	justOptions: SelectOption[];
	preselectedValue: SelectOption;
}

function useSelectStyles( size: SelectSizes ) {
	const { getThemeColor } = useTheme();

	const customStyles = useMemo( () => ({
		control: ( provided: any ) => ({
			...provided,
			borderRadius: '1px',
			borderColor: getThemeColor( 'rural-gray.500' ),
			padding: '2px'
		}),
		indicatorSeparator: ( provided: any ) => ({
			...provided,
			display: 'none'
		}),
		placeholder: ( provided: any ) => ({
			...provided,
			color: getThemeColor( 'rural-gray.400' )
		})
	}), [ getThemeColor ] );

	const miniStyles = useMemo( () => ({
		control: ( provided: any ) => ({
			...provided,
			borderRadius: '1px',
			borderColor: getThemeColor( 'rural-gray.500' ),
			minHeight: 'auto'
		}),
		indicatorSeparator: ( provided: any ) => ({
			...provided,
			display: 'none'
		}),
		dropdownIndicator: ( provided: any ) => ({
			...provided,
			padding: '0 2px',
			minHeight: 'auto'
		}),
		placeholder: ( provided: any ) => ({
			...provided,
			color: getThemeColor( 'rural-gray.400' )
		}),
		valueContainer: ( provided: any ) => ({
			...provided,
			padding: '0 2px'
		})
	}), [ getThemeColor ] );

	const currentStyles = useMemo( () => {
		return size === 'mini' ? miniStyles : customStyles;
	}, [ size, miniStyles, customStyles ] );

	return { currentStyles };
}

export function BaseSelect({
	id,
	defaultValue,
	placeholder,
	options = [],
	onChange = () => {},
	size = 'default'
}: BaseSelectProps ) {
	const { currentStyles } = useSelectStyles( size );

	return (
		<ReactSelect
			instanceId={ id }
			key={ id }
			defaultValue={ defaultValue }
			placeholder={ placeholder }
			options={ options }
			styles={ currentStyles }
			onChange={ onChange }
		/>
	);
}

function SelectInner({
	systemId,
	field,
	hideLabel,
	justOptions,
	label,
	mappedOptions,
	placeholder,
	required,
	size,
	requiredPosition
}: SelectInnerProps ) {
	const { isBlankExport, isExporting } = useIsExporting();

	const { currentStyles } = useSelectStyles( size );

	const { formState: { errors }, resetField, unregister } = useFormContext();

	const { activeQuestions, ConditionalQuestions } = useConditionalQuestions( useMemo( () => ({
		options: mappedOptions,
		selectedValue: field?.value?.value,
		unregister
	}), [ mappedOptions, field.value, unregister ]));

	useEffect( () => {
		if ( typeof field.value === 'string' ) {
			resetField( systemId, {
				defaultValue: field.defaultValue
			});
		}
	}, [ field.defaultValue, field.value, resetField, systemId ] );

	return (
		<div className="keep-it-together">
			<Label
				errors={ <Error id={ systemId } errors={ errors } /> }
				htmlFor={ systemId }
				isRequired={ required }
				hideRequired={ requiredPosition === 'field' }
				isHidden={ hideLabel }
			>
				{ label }
			</Label>

			<div className="max-w-xs">
				<div className="flex gap-x-1">
					{ ( !isBlankExport && isExporting && field?.value?.label ) ? (
						<div className="w-full border border-rural-gray-500 p-2">
							<div className="pr-2">
								{ field.value.label }
							</div>
						</div>
					) : (
						<div className="w-full">
							<ReactSelect
								{ ...field }
								instanceId={ systemId }
								key={ systemId }
								value={ isBlankExport ? undefined : field.value }
								placeholder={ !isBlankExport && placeholder }
								options={ justOptions }
								styles={ currentStyles }
								onChange={ field.onChange }
							/>
						</div>
					)}

					{ ( required && requiredPosition === 'field' ) && (
						<RequiredIndicator />
					)}
				</div>

				{ activeQuestions.length > 0 && (
					<div className="mt-4">
						{ ConditionalQuestions }
					</div>
				)}
			</div>
		</div>
	);
}

export default function Select( props: SelectOuterProps ) {
	const { addPrefix = false, defaultValue = '', isInCpp = true, options = [], systemId } = props;

	const optionsWithoutAdditional = useMemo( () => {
		return options.map( ( option: any ) => {
			const duplicate = { ...option };

			delete duplicate.additionalQuestions;

			return duplicate;
		});
	}, [ options ] );

	const mappedOptions = useMemo( () => {
		return options.map( ( option: any ) => {
			const subOptions = option?.additionalQuestions || [];

			if ( !addPrefix ) {
				return option;
			}

			return {
				...option,
				additionalQuestions: subOptions.map( ( question: any ) => {
					// This is for multi item tables with selects in them
					// All of the checks are for embedded subforms
					if ( question.systemId.startsWith( question.idPrefix ) ) {
						return question;
					}

					let tempId = question.systemId;

					let newSystemId = systemId.split( '.' );

					if ( newSystemId.length > 1 ) {
						newSystemId.pop();

						if ( !tempId.startsWith( newSystemId.join( '.' ) ) ) {
							newSystemId.push( tempId );

							tempId = newSystemId.join( '.' );
						}
					}

					return {
						...question,
						systemId: tempId
					};
				}) || []
			};
		});
	}, [ addPrefix, options, systemId ] );

	const preselectedValue = useMemo( () => {
		if ( defaultValue === null ) {
			return null;
		}

		return optionsWithoutAdditional.find( ( option: any ) => option.value == defaultValue );
	}, [ defaultValue, optionsWithoutAdditional ] );

	if ( !isInCpp ) {
		return (
			<SelectController
				{ ...props }
				mappedOptions={ mappedOptions }
				justOptions={ optionsWithoutAdditional }
				preselectedValue={ preselectedValue }
			/>
		);
	}

	return (
		<CppSelect
			{ ...props }
			mappedOptions={ mappedOptions }
			justOptions={ optionsWithoutAdditional }
			preselectedValue={ preselectedValue }
		/>
	);
}

function CppSelect( props: SelectControllerProps ) {
	const { justOptions } = props;

	const { cppData } = useCpp();

	const validOptions = useMemo( () => {
		return justOptions.filter( ( option: SelectOption ) => {
			if ( !option.validation || option.validation.length === 0 ) {
				return true;
			}

			return option.validation
				.filter( ( validator: Validator ) => validator.fieldType === 'EffectiveDateComparison' )
				.every( ( validator: ValidationEffectiveDate ) => compareDates( getDateFromSquidex( validator.date ), cppData.quoteEffectiveDate, validator.timePeriod ) );
		});
	}, [ cppData.quoteEffectiveDate, justOptions ] );

	return (
		<SelectController
			{ ...props }
			justOptions={ validOptions }
		/>
	);
}

function SelectController({
	hideLabel = false,
	label,
	mappedOptions,
	justOptions,
	placeholder = 'Please select...',
	preselectedValue,
	required = false,
	requiredPosition,
	size = 'default',
	systemId
}: SelectControllerProps ) {
	const { control } = useFormContext();

	return (
		<Controller
			name={ systemId }
			control={ control }
			rules={{ required }}
			defaultValue={ preselectedValue }
			render={ ({ field }) => (
				<SelectInner
					field={ field }
					hideLabel={ hideLabel }
					justOptions={ justOptions }
					label={ label }
					mappedOptions={ mappedOptions }
					placeholder={ placeholder }
					required={ required }
					requiredPosition={ requiredPosition }
					size={ size }
					systemId={ systemId }
				/>
			)}
		/>
	);
}
