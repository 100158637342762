import type { ReactNode } from 'react';

type ContainerProps = {
	children: ReactNode;
	className?: string;
	innerClassName?: string;
};

export default function Container({ children, className, innerClassName }: ContainerProps ) {
	return (
		<div className={ `py-8 ${ className }` }>
			<div className={ `container mx-auto ${ innerClassName }` }>
				{ children }
			</div>
		</div>
	);
}
