/** @type {import('tailwindcss').Config} */

const tailwindContainerBleed = require( 'tailwindcss-container-bleed' );

module.exports = {
	content: [
		"./pages/**/*.{js,ts,jsx,tsx}",
		"./components/**/*.{js,ts,jsx,tsx}",
		"./layouts/**/*.{js,ts,jsx,tsx}",
	],
	theme: {
		container: {
			center: true,
			padding: '2rem'
		},
		extend: {
			aspectRatio: {
				'image': '1.7 / 1.3'
			},
			colors: {
				'rural-gray': {
					100: '#f0f0f0',
					150: '#f5f5f5',
					200: '#ebebeb',
					300: '#dddddd',
					400: '#aaaaaa',
					500: '#999999',
					600: '#787878',
					700: '#666666',
					800: '#4d4d4d'
				},
				'rural-red': {
					50: 'rgba(239, 55, 37, 0.10)',
					75: 'rgba(211, 9, 19, 0.05)',
					100: 'rgba(211, 9, 19, 0.15)',
					150: 'rgba(239, 55, 37, 0.1)',
					200: 'rgba(239, 55, 37, 0.2)',
					800: 'rgba(239, 55, 37, 1)',
					900: '#d30913'
				},
				'gray-overlay': {
					400: 'rgba(88, 88, 88, 0.4)',
					800: 'rgba(88, 88, 88, 0.85)'
				}
			}
		},
	},
	plugins: [
		tailwindContainerBleed({
			paddingVar: '--adjusted-padding'
		})
	],
};
