import type { ReactNode } from 'react';

import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useRouter } from 'next/router';

import { useForms } from '@/hooks/useForms';
import { activateOrInactivateCpp, getCpp } from '@/services/webservices/cpp';

type CppProviderProps = {
	children: ReactNode;
	cppId: string;
};

type CppDetails = {
	cppData: any;
	cppId: string;
	displayId: string;
	isCppInactive: boolean | undefined;
	isCppLoading: boolean;
	isCppMidterm: boolean;
	refetchCppData: Function;
	toggleActivationStatus: Function;
};

type CppContextExports = CppDetails & {
	applications: any[];
	questionnaires: any[];
};

const CppContext = createContext<CppContextExports | undefined>( undefined );

function CppProvider({
	children,
	cppId
}: CppProviderProps ) {
	const loadedId = useRef<string | undefined>();

	const { push } = useRouter();

	const { formsData } = useForms();

	const [ cppData, setCppData ] = useState<any>( {} );
	const [ isCppLoading, setIsCppLoading ] = useState( false );

	const fetchCppData = useCallback( async () => {
		if ( !cppId ) {
			return;
		}

		setIsCppLoading( true );

		try {
			const cppData = await getCpp( cppId );

			if ( cppData.id ) {
				setCppData( cppData );
			} else {
				setCppData( {} );
			}
		} catch ( e ) {
			console.log( `There is an error in the API. ${ e }` );

			push( '/404' );
		} finally {
			setIsCppLoading( false );
		}
	}, [ cppId, push, setIsCppLoading ] );

	useEffect( () => {
		if ( !cppId || cppId === loadedId.current ) {
			return;
		}

		loadedId.current = cppId;

		fetchCppData();
	}, [ cppId ] );

	const isCppInactive = useMemo( () => {
		return cppData?.inactive || undefined;
	}, [ cppData?.inactive ] );

	const isCppMidterm = useMemo( () => {
		return cppData?.isMidterm || false;
	}, [ cppData?.isMidterm ] );

	const toggleActivationStatus = useCallback( async () => {
		const activationRequest = await activateOrInactivateCpp( cppId, !isCppInactive );

		if ( activationRequest.status === 200 ) {
			await fetchCppData();
		}
	}, [ cppId, isCppInactive, fetchCppData ] );

	const applications = useMemo( () => {
		if ( !cppData.applications || !formsData ) {
			return [];
		}

		return cppData.applications?.map( ( form: any ) => {
			const matchedForm = formsData.find( ( formData: any ) => formData.id === form.applicationTypeGuid );

			if ( !matchedForm ) {
				return undefined;
			}

			return {
				name: matchedForm.title,
				id: form.id,
				applicationTypeGuid: matchedForm.id,
				validators: matchedForm.validators
			};
		}).filter( ( a: any ) => a ) || [];
	}, [ cppData?.applications, formsData ] );

	const questionnaires = useMemo(() => {
		if ( !cppData.applications || !formsData ) {
			return [];
		}

		return cppData.questionnaires?.map( ( form: any ) => {
			const matchedForm = formsData.find( ( formData: any ) => formData.id === form.applicationTypeGuid );

			if ( !matchedForm ) {
				return undefined;
			}

			return {
				name: matchedForm.title,
				id: form.id,
				applicationTypeGuid: matchedForm.id,
				validators: matchedForm.validators
			};
		}).filter( ( a: any ) => a ) || [];
	}, [ cppData?.questionnaires, formsData ] );

	const displayId = useMemo( () => {
		if ( !cppData ) {
			return '';
		}

		return cppData.displayId ? cppData.displayId : cppData.id;
	}, [ cppData ] );

	const value: CppContextExports = useMemo( () => {
		return {
			cppData,
			cppId,
			displayId: displayId || '',
			refetchCppData: fetchCppData,
			isCppInactive,
			isCppLoading,
			isCppMidterm,
			toggleActivationStatus,
			applications,
			questionnaires
		};
	}, [ cppData, cppId, displayId, fetchCppData, isCppInactive, isCppLoading, isCppMidterm, toggleActivationStatus, applications, questionnaires ] );

	return (
		<CppContext.Provider value={ value }>
			{ children }
		</CppContext.Provider>
	);
}

function useCpp() {
	const context = useContext( CppContext );

	if ( context === undefined ) {
		throw new Error( 'useCpp must be used within a CppProvider' );
	}

	return context;
}

export { CppProvider, useCpp };
