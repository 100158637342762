import Button from '@/components/Button';
import Header from '@/components/Header';

type ConfirmChildUpdateProps = {
	onCancel: any;
	onConfirm: any;
};

export default function ConfirmChildUpdate({
	onCancel = () => {},
	onConfirm = () => {}
}: ConfirmChildUpdateProps ) {
	return (
		<div>
			<Header element="h2" variant="h3">Update Risk Deductibles?</Header>

			<p className="text-md">The deductible for all risks at this location will be changed. Are you sure?</p>

			<div className="flex gap-x-4 mt-8">
				<Button type="button" variant="primary" size="large" onClick={ onConfirm }>Yes, save</Button>
				<Button type="button" variant="border" size="large" onClick={ onCancel }>Cancel</Button>
			</div>
		</div>
	);
}
