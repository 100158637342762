import Button from '@/components/Button';
import Header from '@/components/Header';

type ConfirmDeleteProps = {
	itemCount: number;
	onCancel: any;
	onConfirm: any;
};

export default function ConfirmDelete({
	itemCount,
	onCancel = () => {},
	onConfirm = () => {}
}: ConfirmDeleteProps ) {
	return (
		<div>
			<Header element="h2" variant="h3">Are you sure you want to delete the { itemCount > 1 ? itemCount : '' } item{ itemCount > 1 ? 's' : '' } you have selected? This cannot be undone.</Header>

			<div className="flex gap-x-4 mt-8">
				<Button type="button" variant="primary" size="large" onClick={ onConfirm }>Yes, delete</Button>
				<Button type="button" variant="border" size="large" onClick={ onCancel }>Cancel</Button>
			</div>
		</div>
	);
}
