import DatePicker from 'react-datepicker';
import { IMaskInput } from 'react-imask';
import { Controller, useFormContext } from 'react-hook-form';

import { Error, Label, RequiredIndicator } from '@/components/Form';

import { useIsExporting } from '@/hooks/useIsExporting';
import { formatDate, isValidDate, unserializeDate } from '@/services/dates';

import 'react-datepicker/dist/react-datepicker.css';

type Size = 'mini' | 'medium';

type DateProps = {
	className?: string;
	hideLabel?: boolean;
	label: string;
	required?: boolean;
	size?: Size;
	systemId: string;
	requiredPosition?: RequiredPosition;
};

const sizeMap: Record<Size, string> = {
	mini: 'p-1',
	medium: 'p-2'
};

export default function Date({
	className = '',
	hideLabel = false,
	label,
	required = true,
	size = 'medium',
	systemId,
	requiredPosition = 'label'
}: DateProps ) {
	const { control, formState: { errors } } = useFormContext();
	const { isBlankExport, isExporting } = useIsExporting();

	return (
		<div className={ `max-w-xs ${ className }` }>
			<Label
				errors={ <Error id={ systemId } errors={ errors } /> }
				htmlFor={ systemId }
				isRequired={ required }
				hideRequired={ requiredPosition === 'field' }
				isHidden={ hideLabel }
			>
				{ label }
			</Label>
			
			<Controller
				name={ systemId }
				control={ control }
				rules={{ required }}
				render={ ({ field }) => {
					// check for correct date format in case of old data
					// shouldn't be necessary for new installations/once data has been cleared.
					const dateObject = unserializeDate( field.value );
					let fieldValue;

					if ( isValidDate( dateObject ) ) {
						fieldValue = dateObject;
					}

					return (
						<div ref={ field.ref } tabIndex={ 0 } className="flex gap-x-1">
							{ !isBlankExport && isExporting ? (
								<div className="border border-rural-gray-500 bg-white w-full placeholder:text-rural-gray-400 p-2">
									{ formatDate( field.value ) || '\u00A0' }
								</div>
							) : (
								<DatePicker
									name={ field.name }
									onBlur={ field.onBlur }
									onChange={ field.onChange }
									selected={ fieldValue }
									dateFormat="MM/dd/yyyy"
									placeholderText="mm/dd/yyyy"
									customInput={
										<IMaskInput
											mask="00/00/0000"
											overwrite={ true }
											placeholderChar="#"
											// @ts-ignore
											placeholder="mm/dd/yyyy"
											className={ `border border-rural-gray-500 flex w-full datepicker-icon placeholder:text-rural-gray-400 ${ sizeMap[ size ] }` }
										/>
									}
								/>
							)}

							{ ( required && requiredPosition === 'field' ) && (
								<RequiredIndicator />
							)}
						</div>
					);
				}}
			/>
		</div>
	);
}
