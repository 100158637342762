export function validatePhone( val: string, isRequired: boolean ) {
	if ( !isRequired && !!val ) {
		return true;
	}

	return val?.length === 10 || 'Phone numbers must contain 10 digits';
}

export function maxLength( val: string, maxLength: number ) {
	if ( !val ) {
		return true;
	}

	return val.length <= maxLength || `This field may not contain more than ${ maxLength } characters`;
}

export function isNumeric( val: any, isRequired: boolean ) {
	if ( !isRequired && !val ) {
		return true;
	}

	return !isNaN( val ) || 'This value must be numeric';
}

export function noCommas( val: string ) {
	return !val || val.indexOf(',') === -1; 
}

export function isLessThan( val: any, maxValue: number, isRequired: boolean ) {
	if ( !isRequired && !val ) {
		return true;
	}

	return val <= maxValue || `This value may not be greater than ${ maxValue }`;
}

export function isGreaterThan( val: any, minValue: number, isRequired: boolean ) {
	if ( !isRequired && !val ) {
		return true;
	}

	return val >= minValue || `This value must be at least ${ minValue }`;
}

export function isBetween( val: any, minValue: number, maxValue: number, isRequired: boolean ) {
	if ( !isRequired && !val ) {
		return true;
	}
	
	return val >= minValue && val <= maxValue || `This value must be between ${ minValue } and ${ maxValue }`;
}
