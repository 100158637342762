import { useCallback, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import parse from 'html-react-parser';

import { Error } from '@/components/Form';
import Grid, { Column } from '@/components/Grid';

import { useConditionalQuestions } from '@/hooks/useConditionalQuestions';
import { useIsExporting } from '@/hooks/useIsExporting';

type YesNoToggleProps = {
	field: any;
};

type YesNoProps = {
	systemId: string;
	identifier: string;
	label: string;
	defaultValue: 'yes' | 'no' | null;
	additionalYes?: unknown[];
	additionalNo?: unknown[];
	shouldHideNoResponses?: boolean;
	hideIfNo?: boolean;
};

type YesNoInnerProps = YesNoProps & {
	field: any;
};

function YesNoToggle({
	field
}: YesNoToggleProps ) {
	const baseButtonClass = 'rounded py-1 px-3';
	const inactiveButton = 'text-rural-gray-600';
	const activeButton = 'bg-rural-red-900 text-white';

	const getActiveClass = useCallback( ( activeState: string ) => {
		if ( field.value === activeState ) {
			return `${ baseButtonClass } ${ activeButton }`;
		} else {
			return `${ baseButtonClass } ${ inactiveButton }`;
		}
	}, [ field.value ] );

	return (
		<div className="flex items-center gap-4">
			<button
				type="button"
				className={ getActiveClass( 'yes' ) }
				onClick={ () => field.onChange( 'yes' ) }
			>
				Yes
			</button>

			<button
				type="button"
				className={ getActiveClass( 'no' ) }
				onClick={ () => field.onChange( 'no' ) }
			>
				No
			</button>
		</div>
	);
}

function YesNoInner({
	systemId,
	field,
	identifier,
	label,
	additionalYes = [],
	additionalNo = [],
	shouldHideNoResponses,
	hideIfNo
}: YesNoInnerProps ) {
	const { isBlankExport, isExporting } = useIsExporting();
	const { formState: { errors }, unregister } = useFormContext();

	const combinedOptions = useMemo( () => {
		let returnVal = [];

		if ( additionalYes.length > 0 ) {
			returnVal.push({ value: 'yes', additionalQuestions: additionalYes });
		}

		if ( additionalNo.length > 0 ) {
			returnVal.push({ value: 'no', additionalQuestions: additionalNo });
		}

		return returnVal;
	}, [ additionalYes, additionalNo ] );

	const { activeQuestions, ConditionalQuestions } = useConditionalQuestions( useMemo( () => ({
		options: combinedOptions,
		selectedValue: field.value,
		unregister
	}), [ combinedOptions, field.value, unregister ] ));

	// we need to hide yes/no fields for sections that have been specifically
	// marked as wanting to hide yes/no responses on PDF export.
	if ( !isBlankExport && isExporting && ( shouldHideNoResponses || hideIfNo ) && field.value === 'no' ) {
		return null;
	}

	return (
		<div ref={ field.ref } tabIndex={ 0 }>
			<div className="border-b border-rural-gray-500 text-sm p-2">
				<Grid className="keep-it-together">
					<Column columns={ 1 }>
						{ identifier && <span>{ identifier }</span> }
					</Column>

					<Column columns={ 9 }>
						<div className="[&>strong]:font-bold [&>em]:italic">
							{ parse( label ) }
						</div>
						
						<Error id={ systemId } errors={ errors } />
					</Column>

					<Column columns={ 2 } className="ml-auto">
						<YesNoToggle field={ field } />
					</Column>
				</Grid>

				{ activeQuestions.length > 0 && (
					<Grid className="questionTable mt-4">
						<Column columns={ 1 } className="questionTable-identifier" />
		
						<Column columns={ 11 } className="questionTable-yesnomain">
							{ ConditionalQuestions }
						</Column>
					</Grid>
				)}
			</div>
		</div>
	);
}

export default function YesNo( props: YesNoProps ) {
	const { isBlankExport } = useIsExporting();
	const { control } = useFormContext();

	return (
		<Controller
			name={ props.systemId }
			control={ control }
			rules={{ required: true }}
			defaultValue={ isBlankExport ? undefined : props.defaultValue }
			render={ ({ field }) => (
				<YesNoInner
					field={ field }
					{ ...props }
				/>
			)}
		/>
	);
}
