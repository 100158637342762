export const ENDPOINT_URL = process.env.NEXT_PUBLIC_API_ENDPOINT;

export async function apiCall( url: string, options: any = {}, errorMsg: string = '', returnJson: boolean = true ) {
	const callResponse = await fetch( `${ ENDPOINT_URL }${ url }`, options );

	if ( !callResponse.ok ) {
		throw new Error( errorMsg || `There was an error calling this endpoint: ${ url }` );
	}

	return returnJson ? callResponse.json() : callResponse;
}

export function getBaseApiUrl() {
	return ENDPOINT_URL;
}