import type { ChangedItems } from '@/definitions/effectiveChangeTable';

import { useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import Button from '@/components/Button';
import Header from '@/components/Header';
import Table from '@/components/Table';
import { Date } from '@/components/FormElements';

import { changedItemsColumns } from '@/definitions/effectiveChangeTable';

type ChangeEffectiveDateProps = {
	currentCppEffectiveDate: string,
	title?: string;
	onCancel?: () => void;
	onSuccess?: ( cppEffectiveDate: Date ) => Promise<void>;
	onCheck?: ( newEffectiveDate: Date ) => any;
};

type PostChangeDifferencesProps = {
	changedItems: ChangedItems[];
	changedForms: ChangedItems[];
};

function PostChangeDifferences({
	changedItems,
	changedForms
}: PostChangeDifferencesProps ) {
	const combinedChanges = useMemo( () => {
		return [ ...changedItems, ...changedForms ];
	}, [ changedItems, changedForms ] );

	return (
		<div className="mt-4">
			{ combinedChanges.length > 0 ? (
				<>
					<p className="mb-4">Please note that changing the effective date means the following coverage options may have also changed.</p>

					<div className="mb-6">
						<Table
							variant="mini"
							data={ combinedChanges }
							columns={ changedItemsColumns }
						/>
					</div>
				</>
			) : (
				<p className="mb-4">Changing the effective date will not result in any coverage changes.</p>
			)}
		</div>
	);
}

export default function ChangeEffectiveDate({
	title,
	onCancel = () => {},
	onSuccess = async () => {},
	onCheck
}: ChangeEffectiveDateProps ) {
	const [ changedForms, setChangedForms ] = useState<ChangedItems[] | undefined>( undefined );
	const [ changedItems, setChangedItems ] = useState<ChangedItems[] | undefined>( undefined );
	const [ pendingDate, setPendingDate ] = useState<Date | undefined>();
	const { handleSubmit, reset } = useFormContext();

	const clearPending = useCallback( () => {
		setChangedForms( undefined );
		setChangedItems( undefined );
		setPendingDate( undefined );
	}, [ setChangedForms, setChangedItems, setPendingDate ] );

	const onClear = useCallback( () => {
		reset();
		clearPending();
		onCancel();
	}, [ onCancel, reset, clearPending ] );

	const finalize = useCallback( async ( effectiveDate?: Date ) => {
		if ( effectiveDate ) {
			await onSuccess( effectiveDate );

			onClear();
		}
	}, [ onClear, onSuccess ] );

	const onSubmit = useCallback( async ( data: any ) => {
		// if the onCheck function is there, do the check, otherwise do the success callback
		if ( typeof onCheck !== 'undefined' ) {
			const checkResults = await onCheck( data.cppEffectiveDate );
	
			setChangedItems( checkResults.changedItems );
			setChangedForms( checkResults.changedForms );
			setPendingDate( data.cppEffectiveDate );

			return;
		}

		finalize( data.cppEffectiveDate );
	}, [ finalize, onCheck ] );

	return (
		<div>
			<Header element="h2" variant="h3">{ title || "Change CPP Effective Date" }</Header>

			{ !pendingDate && (
				<form onSubmit={ handleSubmit( onSubmit ) }>
					<Date
						label="CPP Effective Date"
						systemId="cppEffectiveDate"
					/>

					<div className="flex gap-x-4 mt-4">
						<Button type="submit" variant="primary" size="medium">Continue</Button>
						<Button type="button" variant="border" size="medium" onClick={ () => onClear() }>Cancel</Button>
					</div>
				</form>
			)}

			{ ( changedItems !== undefined && changedForms !== undefined ) && (
				<>
					<PostChangeDifferences
						changedForms={ changedForms }
						changedItems={ changedItems }
					/>

					<div className="flex gap-x-4 mt-4">
						<Button type="button" variant="primary" size="medium" onClick={ () => finalize( pendingDate ) }>Submit</Button>
						<Button type="button" variant="border" size="medium" onClick={ () => clearPending() }>Cancel</Button>
					</div>
				</>
			)}
		</div>
	);
}
