import type { ReactNode } from 'react';

import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import { defaultState } from '@/definitions/occurrenceDefaults';

type OccurrenceFormProviderProps = {
	children: ReactNode;
	setValueWithParent: Function;
	systemId: string;
};

type OccurrenceFormContextProps = {
	defaultState: any;
	selectedColumn: number | undefined;
	setSelectedColumn: Function;
	tableValues: any;
	setTableValues: Function;
	updateCellValue: Function;
	setColumnValue: Function;
	selectedValues: any;
	control: any;
	register: Function;
	setValue: Function;
};

const OccurrenceFormContext = createContext<OccurrenceFormContextProps | undefined>( undefined );

function OccurrenceFormProvider({
	children,
	setValueWithParent,
	systemId
}: OccurrenceFormProviderProps ) {
	const selectedValues = useWatch({
		name: systemId
	});

	const [ selectedColumn, setSelectedColumn ] = useState<number | undefined>( undefined );
	const [ tableValues, setTableValues ] = useState( defaultState );
	
	const { control, getValues, register, setValue } = useForm({
		defaultValues: { occurrencesub: defaultState }
	});

	useEffect( () => {
		if ( !selectedValues ) {
			setSelectedColumn( undefined );

			return;
		}

		setSelectedColumn( selectedValues.columnIndex );
	}, [ selectedValues, setSelectedColumn ] );

	const updateCellValue = useCallback( ( columnIndex: number, cellKey: string, cellValue: string ) => {
		const currentValues = getValues( `occurrencesub` );
		const newObject: any = { ...currentValues[ columnIndex ] };

		newObject[ cellKey ] = cellValue;

		setTableValues( ( previousState: any ) => {
			const copiedArray = [ ...previousState ];

			copiedArray[ columnIndex ] = newObject;

			return copiedArray;
		});

		if ( selectedColumn == columnIndex + 2 ) {
			setValueWithParent( systemId, {
				columnIndex: selectedColumn,
				tableValues: newObject
			});
		}
	}, [ getValues, selectedColumn, setTableValues, setValueWithParent, systemId ] );

	const setColumnValue = useCallback( ( columnIndex: number ) => {
		const currentValues = getValues( 'occurrencesub' );

		// if the clicked column matches the selected column, reset value to null
		if ( selectedColumn === columnIndex ) {
			setValueWithParent( systemId, null );

			return;
		}

		// otherwise update the value with the matching table values
		setValueWithParent( systemId, { columnIndex, tableValues: currentValues[ columnIndex - 2 ] } );
	}, [ selectedColumn, setValueWithParent, systemId ] );

	const returnValue: OccurrenceFormContextProps = {
		defaultState,
		selectedColumn,
		setSelectedColumn,
		tableValues,
		setTableValues,
		setColumnValue,
		updateCellValue,
		selectedValues,
		control,
		register,
		setValue
	};

	return (
		<OccurrenceFormContext.Provider value={ returnValue }>
			{ children }
		</OccurrenceFormContext.Provider>
	);
}

function useOccurrenceForm() {
	const context = useContext( OccurrenceFormContext );

	if ( context === undefined ) {
		throw new Error( 'useOccurrenceForm must be used within a OccurrenceFormProvider' );
	}

	return context;
}

export { OccurrenceFormProvider, useOccurrenceForm };
