import Link from 'next/link';
import Button from '@/components/Button';

import Container from '@/components/Container';
import Logo from '@/components/Logo';

import { useMe } from '@/hooks/useMe';
import Loading from 'react-loading';

function Greeting() {
	const {
		isLoggedIn,
		loginUrl,
		logoutUrl,
		name,
		isLoggingIn
	} = useMe();
	
	return (
		<div>
			{ isLoggedIn ? (
				<div className="flex justify-start gap-x-4">
					<div className="self-center">
						{ name ? `Hi, ${ name }` : 'Hello!' }
					</div>
					
					<div>
						<Button href={ logoutUrl } variant="outline" size="medium" isHtmlLink>Sign-out</Button>
					</div>
				</div>
			) : (
				<div className="flex gap-x-4 ">
					<Button href={ loginUrl } variant="outline" size="medium" isHtmlLink>Sign-in</Button>
				</div>
			)}

			{ !isLoggedIn && isLoggingIn && (
					<div className="absolute -top-2 -bottom-2 -right-2 -left-2 flex items-center justify-center bg-slate-200/80">
						<Loading type="spin" color="#676767" width={ 64 } height={ 64 } />
					</div>
				)}
		</div>
	);
}

export default function SiteHeader() {
	return (
		<Container>
			<div className="flex justify-between items-center">
				<Link href="/dashboard">
					<Logo />
				</Link>

				<Greeting />
			</div>
		</Container>
	);
}
