import type { Dispatch, PropsWithChildren, SetStateAction } from 'react';
import type { ActiveFilters } from '@/services/webservices/cpp';

import { createContext, useContext, useEffect, useRef, useMemo, useState } from 'react';

import { useMe } from '@/hooks/useMe';
import { isUnderwriter } from '@/definitions/userRoles';

type FiltersProviderProps = PropsWithChildren;

type FiltersContextProps = {
	filters: ActiveFilters;
	setFilters: Dispatch<SetStateAction<ActiveFilters>>;
};

const FiltersContext = createContext<FiltersContextProps | undefined>( undefined );

function FiltersProvider({ children }: FiltersProviderProps ) {
	const checkedDefault = useRef( false );

	const [ filters, setFilters ] = useState<ActiveFilters>( {} );
	
	const { name, role, underwriterNumber } = useMe();

	useEffect( () => {
		if ( checkedDefault.current ) {
			return;
		}

		if ( role && !isUnderwriter( role ) ) {
			checkedDefault.current = true;

			return;
		}

		if ( name && underwriterNumber ) {
			setFilters({
				underwriter: [{
					label: name,
					value: underwriterNumber
				}]
			});

			checkedDefault.current = true;
		}
	}, [ name, role, setFilters, underwriterNumber ] );

	const returnValues = useMemo( () => ({
		filters,
		setFilters
	}), [ filters, setFilters ] );

	return (
		<FiltersContext.Provider value={ returnValues }>
			{ children }
		</FiltersContext.Provider>
	);
}

function useFilters() {
	const context = useContext( FiltersContext );

	if ( context === undefined ) {
		throw new Error( 'useFilters must be used within a FiltersProvider' );
	}

	return context;
}

export { FiltersProvider, useFilters };
