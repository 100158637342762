import { useCallback, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';

import { clearSavedForm } from '@/services/localStorage';

function throwFakeErrorToFoolNextRouter(): never {
	throw 'Abort route change. Please ignore this error.';
}

function useNavigationPrompt( isEnabled: boolean, shouldWarn: boolean, hasBeenSubmitted: boolean, prompt: string ): void {
	const router = useRouter();

	const lastHistoryState = useRef<any>( global.history?.state );

	const revertTheChangeRouterJustMade = useCallback( () => {
		const state = lastHistoryState.current;

		if ( state !== null && history.state !== null && state.key !== history.state.key ) {
			history.go( 1 );
		}
	}, [] );

	const killRouterEvent = useCallback( () => {
		router.events.emit( 'routeChangeError' );

		revertTheChangeRouterJustMade();

		throwFakeErrorToFoolNextRouter();
	}, [ revertTheChangeRouterJustMade, router ] );

	useEffect( () => {
		const storeLastHistoryState = (): void => {
			lastHistoryState.current = history.state;
		};

		router.events.on( 'routeChangeComplete', storeLastHistoryState );

		return () => {
			router.events.off( 'routeChangeComplete', storeLastHistoryState );
		};
	}, [ router ] );

	useEffect( () => {
		if ( !isEnabled ) {
			return;
		}

		let isWarned = false;

		const routeChangeStart = ( url: string ): void => {
			if ( router.asPath !== url && shouldWarn && !isWarned && !hasBeenSubmitted ) {
				isWarned = true;

				if ( window.confirm( prompt ) ) {
					router.push( url );
					
					clearSavedForm();

					return;
				}

				isWarned = false;

				killRouterEvent();
			}
		};

		const beforeUnload = ( e: BeforeUnloadEvent ): string | null => {
			if ( shouldWarn && !isWarned && !hasBeenSubmitted ) {
				const event = e ?? window.event;

				event.returnValue = prompt;

				return prompt;
			}

			return null;
		};

		router.events.on( 'routeChangeStart', routeChangeStart );
		window.addEventListener( 'beforeUnload', beforeUnload );

		return () => {
			router.events.off( 'routeChangeStart', routeChangeStart );
			window.removeEventListener( 'beforeUnload', beforeUnload );
		};
	}, [ isEnabled, prompt, shouldWarn, hasBeenSubmitted, killRouterEvent, router ] );
}

export { useNavigationPrompt };
