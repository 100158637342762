import type { PropsWithChildren } from 'react';

import { createContext, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { useRouter } from 'next/router';

import { apiCall } from '@/services/webservices/shared';

type MeProviderProps = PropsWithChildren;

type UserLoginProps = {
	name: string;
	username: string;
	role: string;
	subAgentNumber: string;
	underwriterNumber: string;
};

type MeContextProps = UserLoginProps & {
	isLoggedIn: boolean;
	isLoggingIn: boolean;
	loginUrl: string;
	logoutUrl: string;
};

type UserActions =
	| { type: 'log_in', payload: UserLoginProps }
	| { type: 'log_out' };

const defaultUserObject = {
	username: '',
	name: '',
	role: '',
	subAgentNumber: '',
	underwriterNumber: ''
};

const loginUrl = `/.auth/login/okta`;
const logoutUrl = `/.auth/logout`;

const MeContext = createContext<MeContextProps | undefined>( undefined );

export async function getClaimPrincipal(): Promise<any> {
	const oktaRequestBody = await fetch( '/.auth/me' )
		.then( async response => {
			const data = await response.json();
			
			if ( !response.ok ) {
				const error = ( data && data.message ) || response.statusText;
				
				return Promise.reject( error );
			}
			
			return data;
		}).catch( error => {
			'There was an error fetching okta token!';
			
			console.error('There was an error!', error);
		});
	
		return oktaRequestBody;
}

export async function ensureUserDetails( oktaRequestBody: any ): Promise<AgentTokenInformation> {
	return await apiCall( '/User/user-information', {
		method: 'POST',
		body: JSON.stringify( oktaRequestBody ),
		headers: {
			'Content-Type': 'application/json'
		}
	}, 'There was an error fetching user details!' );
}

function userReducer( user: UserLoginProps, action: UserActions ) {
	switch ( action.type ) {
		case 'log_in': {
			return { ...user, ...action.payload };
		}

		case 'log_out': {
			return { ...defaultUserObject };
		}

		default: {
			throw Error( 'Unknown login action: ' + ( action as any ).type );
		}
	}
}

function MeProvider({ children }: MeProviderProps ) {
	const { push } = useRouter();

	const [ isLoggedIn, setIsLoggedIn ] = useState( false );
	const [ isLoggingIn, setIsLoggingIn ] = useState( true );

	const [ loggedInUser, dispatch ] = useReducer( userReducer, { ...defaultUserObject } );

	useEffect( () => {
		async function handleLogin() {
			setIsLoggingIn( true );

			const loggedInUser = { ...defaultUserObject };

			let claimPrincipal;
			let userDetails;

			try {
				claimPrincipal = await getClaimPrincipal();
			} catch ( e: any ) {
				setIsLoggingIn( false );

				throw new Error( 'There was an error ensuring agent principal details:', e.toString() );
			}

			if ( claimPrincipal.clientPrincipal == null ) {
				setIsLoggingIn( false );

				return;
			}

			try {
				userDetails = await ensureUserDetails( claimPrincipal );
			} catch ( e: any ) {
				setIsLoggingIn( false );

				push( `/401` );

				throw new Error( 'There was an error ensuring agent principal details: ', e.toString() );
			}

			if ( !userDetails ) {
				setIsLoggingIn( false );

				return;
			}

			const { firstName, lastName, username, role, subAgentNumber, underwriterNumber } = userDetails;

			loggedInUser.username = claimPrincipal.clientPrincipal.userDetails;

			if ( !firstName && !lastName ) {
				loggedInUser.name = username;
			} else {
				loggedInUser.name = `${ firstName } ${ lastName }`;
			}

			if ( role ) {
				loggedInUser.role = role;
				loggedInUser.subAgentNumber = subAgentNumber;
				loggedInUser.underwriterNumber = underwriterNumber;
			}

			dispatch({ type: 'log_in', payload: loggedInUser });

			setIsLoggedIn( true );
			setIsLoggingIn( false );
		}

		handleLogin();
	}, [] );

	const returnValues = useMemo( () => ({
		...loggedInUser,
		isLoggedIn,
		isLoggingIn,
		loginUrl,
		logoutUrl
	}), [ loggedInUser, isLoggedIn, isLoggingIn ] );

	return (
		<MeContext.Provider value={ returnValues }>
			{ children }
		</MeContext.Provider>
	);
}

function useMe() {
	const context = useContext( MeContext );

	if ( context === undefined ) {
		throw new Error( 'useMe must be used within a MeProvider' );
	}

	return context;
}

export { MeProvider, useMe };
