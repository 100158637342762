import { useMemo } from 'react';
import get from 'lodash/get';

type ErrorObject = {
	type: string;
	message?: string;
	ref?: HTMLDivElement;
};

type ErrorProps = {
	className?: string;
	errors: any;
	id: string;
};

function getErrorMessage( errorObject: ErrorObject ) {
	if ( errorObject.type === 'none' ) {
		return '';
	}

	if ( errorObject.message ) {
		return errorObject.message;
	}

	if ( errorObject.type === 'required' ) {
		return 'This field is required.';
	}

	return 'There was an error with this form field.';
}

export default function Error({
	className,
	errors,
	id
}: ErrorProps ) {
	const errorObject = get( errors, id, { type: 'none' } );
	const errorMessage = useMemo( () => {
		return getErrorMessage( errorObject );
	}, [ errorObject ] );

	if ( !errorMessage ) {
		return <></>;
	}

	return (
		<p className={ `text-sm text-red-500 ${ className }` }>{ errorMessage }</p>
	);
}
