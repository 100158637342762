import type { PropsWithChildren } from 'react';
import type { EffectiveDateChanges } from '@/services/forms';

import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

type ModalTypes = 'createcpp' | 'agentsearch' | 'underwritersearch' | 'confirmdelete' | 'confirmcppdelete' | 'confirmapplicationdelete' | 'confirmbuildingupdate' | 'changeeffectivedate' | undefined;

type ModalState = {
	cancel: any;
	isOpen: boolean;
	modalData?: unknown;
	modalStyles?: unknown;
	proceed: any;
	type: ModalTypes;
};

type ModalCallback = {
	type: 'createcpp';
	modalStyles?: unknown;
	isMidterm: boolean;
	onClose?: () => void;
	onConfirm: ( agentData: { agentId: string }, effectiveDate: string, isMidterm: boolean ) => void;
} | {
	type: 'agentsearch';
	modalStyles?: unknown;
	cppId: string;
	agentOptions: any[];
	onSuccess: () => void;
} | {
	type: 'underwritersearch';
	modalStyles?: unknown;
	cppId: string;
	onSuccess: () => void;
} | {
	type: 'confirmdelete';
	modalStyles?: unknown;
	itemCount?: number;
} | {
	type: 'confirmcppdelete';
	modalStyles?: unknown;
	displayId: string;
} | {
	type: 'confirmapplicationdelete';
	modalStyles?: unknown;
	applicationName: string;
} | {
	type: 'confirmbuildingupdate';
	modalStyles?: unknown;
} | {
	type: 'changeeffectivedate';
	currentCppEffectiveDate?: Date;
	modalStyles?: unknown;
	title?: string;
	onCheck?: ( newEffectiveDate: Date ) => Promise<EffectiveDateChanges>;
	onSuccess: ( cppEffectiveDate: Date ) => Promise<void>;
};

type ModalProviderProps = PropsWithChildren;

type ModalContextProps = ModalState & {
	isConfirmed: ( modalValues: ModalCallback ) => Promise<boolean>;
};

const ModalContext = createContext<ModalContextProps | undefined>( undefined );

function ModalProvider({ children }: ModalProviderProps ) {
	const [ modal, setModal ] = useState<ModalState>({
		isOpen: false,
		proceed: null,
		type: undefined,
		modalData: {},
		modalStyles: {},
		cancel: () => {}
	});

	const [ needsCleanup, setNeedsCleanup ] = useState( false );

	const isConfirmed = useCallback( ( modalValues: ModalCallback ) => {
		setNeedsCleanup( true );

		const promise = new Promise( ( resolve, reject ) => {
			const { type, modalStyles, ...rest } = modalValues;
	
			setModal({
				cancel: reject,
				proceed: resolve,
				isOpen: true,
				modalStyles,
				type,
				modalData: { ...rest }
			});
		});

		return promise.then(
			() => {
				setModal({ ...modal, isOpen: false });
				return true;
			},
			() => {
				setModal({ ...modal, isOpen: false });
				return false;
			}
		);
	}, [ setNeedsCleanup, setModal, modal ] );

	useEffect( () => {
		return () => {
			if ( modal.cancel && needsCleanup ) {
				modal.cancel();
			}
		};
	}, [ modal, needsCleanup ] );

	const returnValues = useMemo( () => ({
		...modal,
		isConfirmed
	}), [ modal, isConfirmed ] );

	return (
		<ModalContext.Provider value={ returnValues }>
			{ children }
		</ModalContext.Provider>
	);
}

function useModal() {
	const context = useContext( ModalContext );

	if ( context === undefined ) {
		throw new Error( 'useModal must be used within a ModalProvider' );
	}

	return context;
}

export { ModalProvider, useModal };
