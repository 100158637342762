import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import Button from '@/components/Button';
import Header from '@/components/Header';
import PredictiveSelect from '@/components/PredictiveSelect';

import { getUnderwriters, updateUnderwriter } from '@/services/webservices/cpp';

type UnderwriterSearchProps = {
	onCancel?: Function;
	onSuccess?: Function;
	cppId: string;
};

export default function UnderwriterSearch({
	onCancel = () => {},
	onSuccess = () => {},
	cppId
}: UnderwriterSearchProps ) {
	const { handleSubmit, reset } = useFormContext();

	const onClear = useCallback( () => {
		reset();
		onCancel();
	}, [ onCancel, reset ] );

	const onSubmit = useCallback( async ( data: any ) => {
		const underwriterData = { underwriterId: data?.associatedUnderwriters?.value };

		if ( cppId && underwriterData?.underwriterId ) {
			try {
				await updateUnderwriter( cppId as string, underwriterData?.underwriterId );
				
				onSuccess();
			} catch (e) {
				throw new Error('Error saving the form');
			}
		}
		
		onClear();
	}, [ cppId, onClear, onSuccess ] );

	return (
		<div>
			<Header element="h2" variant="h3">Select Underwriter to associate with this CPP</Header>

			<div className="mt-4">
				<form onSubmit={ handleSubmit( onSubmit ) }>
					<PredictiveSelect
						defaultValue={ undefined }
						isMulti={false}
						name="associatedUnderwriters"
						placeholder="Search by underwriter name..."
						queryFunction={ getUnderwriters }
						queryKey="underwriters"
					/>

					<div className="flex gap-x-4 mt-8">
						<Button type="submit" variant="primary" size="large">Save</Button>
						<Button type="button" variant="border" size="large" onClick={ onClear }>Cancel</Button>
					</div>
				</form>
			</div>
		</div>
	);
}
