import type { ReactNode } from 'react';

import Container from '@/components/Container';
import SiteFooter from '@/components/SiteFooter';
import SiteHeader from '@/components/SiteHeader';

type MainLayoutProps = {
	children: ReactNode;
};

export default function Main({ children }: MainLayoutProps ) {
	return (
		<div className="flex flex-col min-h-screen">
			<SiteHeader />

			<Container>
				{ children }
			</Container>

			<SiteFooter />
		</div>
	);
}
