import type { CheckboxProps } from './Checkbox';

import { Controller, useFormContext } from 'react-hook-form';

import { Checkbox } from '@/components/FormElements';
import { Error, Label } from '@/components/Form';

export type CheckboxListProps = {
	checkboxes: CheckboxProps[];
	label?: string;
	required: boolean;
	systemId: string;
};

type CheckboxListInnerProps = CheckboxListProps & {
	field: any;
};

function CheckboxListInner({
	checkboxes = [],
	field,
	label,
	required,
	systemId
}: CheckboxListInnerProps ) {
	const { formState: { errors } } = useFormContext();

	return (
		<div className="keep-it-together">
			<Label
				errors={ <Error id={ systemId } errors={ errors } /> }
				isRequired={ required }
				hideRequired={ label == null }
				className="mb-3"
			>
				{ label }
			</Label>

			{ checkboxes.map( ( checkbox: CheckboxProps, index: number ) => (
				<Checkbox
					key={ index }
					{ ...checkbox }
					checkedValues={ field.value }
					systemId={ systemId }
					required={ required }
				/>
			))}
		</div>
	);
}

export default function CheckboxList({
	checkboxes = [],
	label,
	required,
	systemId
}: CheckboxListProps ) {
	const { control } = useFormContext();

	return (
		<Controller
			name={ systemId }
			control={ control }
			render={ ({ field }) => (
				<CheckboxListInner
					checkboxes={ checkboxes }
					field={ field }
					label={ label }
					required={ required }
					systemId={ systemId }
				/>
			)}
		/>
	);
}
