import type { ReactNode } from 'react';

import { createContext, useCallback, useContext, useMemo, useReducer } from 'react';

type DrawersProviderProps = {
	children: ReactNode;
};

type DrawersContextProps = {
	currentDrawers: DrawerProps[];
	closeDrawer: Function;
	closeMostRecentDrawer: Function;
	openDrawer: Function;
};

export type DrawerProps = {
	Element: ReactNode;
	title: string | ReactNode;
	drawerId: string;
};

type DrawerReducerState = {
	drawers: DrawerProps[];
};

type DrawersActions =
	| { type: 'add_drawer', payload: DrawerProps }
	| { type: 'remove_drawer', payload: string }
	| { type: 'remove_most_recent' };

function drawersReducer( state: DrawerReducerState, action: DrawersActions ): DrawerReducerState {
	if ( action.type === 'add_drawer' ) {
		return {
			drawers: [ ...state.drawers, { ...action.payload } ]
		};
	}

	if ( action.type === 'remove_drawer' ) {
		const itemToRemove = action.payload;
		const matchedItem = state.drawers.findIndex( ( drawer: DrawerProps ) => drawer.drawerId === itemToRemove );

		let drawersCopy = [ ...state.drawers ];

		if ( matchedItem >= 0 ) {
			drawersCopy.splice( matchedItem, 1 );
		}

		return {
			...state,
			drawers: drawersCopy
		};
	}
	
	if ( action.type === 'remove_most_recent' ) {
		let drawersCopy = [ ...state.drawers ];

		drawersCopy.pop();

		return {
			...state,
			drawers: drawersCopy
		};
	}

	throw Error( 'Unknown action' );
}

const DrawersContext = createContext<DrawersContextProps | undefined>( undefined );

function DrawersProvider({ children }: DrawersProviderProps ) {
	const [ currentDrawers, dispatch ] = useReducer( drawersReducer, { drawers: [] } );

	const openDrawer = useCallback( ( Element: ReactNode, title: string, drawerId: string ) => {
		dispatch({ type: 'add_drawer', payload: { Element, title, drawerId } } );
	}, [ dispatch ] );

	const closeDrawer = useCallback( ( drawerId: string ) => {
		dispatch({ type: 'remove_drawer', payload: drawerId });
	}, [ dispatch ] );

	const closeMostRecentDrawer = useCallback( () => {
		dispatch({ type: 'remove_most_recent' });
	}, [ dispatch ] );

	return (
		<DrawersContext.Provider value={{
			currentDrawers: useMemo( () => currentDrawers.drawers, [ currentDrawers ] ),
			closeDrawer,
			closeMostRecentDrawer,
			openDrawer
		}}>
			{ children }
		</DrawersContext.Provider>
	);
}

function useDrawers() {
	const context = useContext( DrawersContext );

	if ( context === undefined ) {
		throw new Error( 'useDrawers must be used within a DrawersProvider' );
	}

	return context;
}

export { DrawersProvider, useDrawers };
