import type { ReactNode } from 'react';

import { createContext, useCallback, useContext } from 'react';
import resolveConfig from 'tailwindcss/resolveConfig';
import get from 'lodash/get';

import tailwindConfig from '../tailwind.config';

type ThemeProviderProps = {
	children: ReactNode;
};

const ThemeContext = createContext<any>( undefined );

function ThemeProvider({ children }: ThemeProviderProps ) {
	const cssTheme = resolveConfig( tailwindConfig );

	const getThemeColor = useCallback( ( colorKey: string, fallback: string = '' ) => {
		return get( cssTheme?.theme?.colors, colorKey, fallback );
	}, [ cssTheme ] );

	return (
		<ThemeContext.Provider value={{
			getThemeColor
		}}>
			{ children }
		</ThemeContext.Provider>
	);
}

function useTheme() {
	const context = useContext( ThemeContext );

	if ( context === undefined ) {
		throw new Error( 'useTheme must be used within a ThemeProvider' );
	}

	return context;
}

export { ThemeProvider, useTheme };
