import type { MouseEventHandler, ReactNode } from 'react';

import Link from 'next/link';
import Loading from 'react-loading';

type Variant = 'primary' | 'text' | 'outline' | 'border' | 'link';
type Size = 'inline' | 'mini' | 'small' | 'medium' | 'large';
type ButtonType = 'button' | 'submit';

type BaseButton = {
	children: ReactNode;
	variant: Variant;
	size?: Size;
	className?: string;
	isHtmlLink?: boolean;
	disabled?: boolean;
	target?: string;
};

type LinkElementProps = BaseButton & {
	href?: string;
};

type ButtonElementProps = BaseButton & {
	onClick?: MouseEventHandler;
	type?: ButtonType;
	href?: string;
	loading?: boolean;
};

type ButtonProps = BaseButton & LinkElementProps & ButtonElementProps;

const variantMap: Record<Variant, string> = {
	'primary': 'bg-rural-red-900 text-white font-bold',
	'link': 'underline text-blue-500 inline-block',
	'text': 'text-rural-red-900 disabled:text-rural-gray-400',
	'outline': 'border border-rural-red-900 bg-white text-rural-red-900 rounded-sm disabled:border-rural-red-200 disabled:text-rural-red-200',
	'border': 'border border-rural-gray-500 text-sm bg-slate-100 hover:bg-rural-red-900 hover:text-white hover:border-rural-red-900 disabled:bg-slate-50 disabled:text-slate-400 disabled:border-slate-300'
};

const sizeMap: Record<Size, string> = {
	'inline': '',
	'mini': 'px-3 py-1 text-xs',
	'small': 'p-2',
	'medium': 'px-4 py-2',
	'large': 'px-8 py-4 rounded-sm'
};

function LinkElement({
	children,
	variant,
	size = 'inline',
	className,
	href = '#',
	isHtmlLink
}: LinkElementProps ) {
	const buttonClasses = `${ variantMap[ variant ] } ${ sizeMap[ size ] } ${ className }`;

	if ( isHtmlLink ) {
		return <a href={ href } className={ buttonClasses }>{ children }</a>;
	}

	return <Link href={ href } className={ buttonClasses }>{ children }</Link>;
}

function ButtonElement({
	children,
	variant,
	size = 'small',
	className,
	onClick,
	type,
	disabled,
	loading = false
}: ButtonElementProps ) {
	return (
		<span className="inline-flex items-center gap-x-1">
			<button
				type={ type }
				className={ `${ variantMap[ variant ] } ${ sizeMap[ size ] } ${ className }` }
				onClick={ onClick }
				disabled={ disabled }
			>
				{ children }
			</button>

			{ loading && (
				<Loading type="spin" color="#676767" width={ 14 } height={ 14 } />
			)}
		</span>
	);
}

export default function Button({
	children,
	variant,
	size,
	onClick,
	className,
	href,
	type,
	isHtmlLink = false,
	disabled = false,
	loading = false,
	target
}: ButtonProps ) {
	return (
		<>
			{ href ? (
				<LinkElement
					variant={ variant }
					size={ size }
					href={ href }
					className={ className }
					target={ target }
					isHtmlLink={ isHtmlLink }
				>
					{ children }
				</LinkElement>
			) : (
				<ButtonElement
					variant={ variant }
					size={ size }
					onClick={ onClick }
					type={ type }
					className={ className }
					disabled={ disabled }
					loading={ loading }
				>
					{ children }
				</ButtonElement>
			)}
		</>
	);
}
