import AdditionalQuestions from '@/components/AdditionalQuestions';
import { Label } from '@/components/Form';

type AlignmentOption = 'top' | 'center' | 'bottom';
type AlignmentValue = 'items-start' | 'items-center' | 'items-end';

type InputGroupProps = {
	label?: string;
	questions: any[];
	align: AlignmentOption;
};

const alignmentMap: Record<AlignmentOption, AlignmentValue> = {
	top: 'items-start',
	center: 'items-center',
	bottom: 'items-end'
};

export default function InputGroup({
	label,
	questions,
	align = 'top'
}: InputGroupProps ) {
	const baseClass = 'flex gap-x-4';

	const alignment: AlignmentValue = alignmentMap[ align ] || 'items-start';

	return (
		<>
			{ label && (
				<Label>{ label }</Label>
			)}

			<div className={ `${ baseClass } ${ alignment }` }>
				<AdditionalQuestions questions={ questions } />
			</div>
		</>
	);
}
